<template>
	<div class="filter-panel">
        <span class="filter-panel__border"></span>
<!--        <BaseSelectMultiple-->
<!--            class="filter-panel__select filter-panel__select&#45;&#45;clients"-->
<!--            view="primary"-->
<!--            position="left"-->
<!--            :options="clients"-->
<!--            :value="selectedClients"-->
<!--            :canSearch="true"-->
<!--            :defaultTitle="clientTitle"-->
<!--            preview-icon="icon-tabler-icon-users"-->
<!--            @check="onCheckCity"-->
<!--        >-->
<!--            <template #preview>-->
<!--                <BaseButton-->
<!--                    class="filter-panel-date"-->
<!--                    :active="isDatepickerOpen"-->
<!--                >-->
<!--                    <span class="filter-panel__icon icon-tabler-icon-calendar-minus"></span>-->
<!--                    <span class="filter-panel-date__title">{{dateTitle}}</span>-->
<!--                </BaseButton>-->
<!--            </template>-->

<!--            <template #header>-->
<!--                <span class="filter-panel__select-title">Клиенты</span>-->
<!--            </template>-->
<!--            <template #header-options>-->
<!--                <div class="filter-panel__select-header-wrap">-->
<!--                    <BaseButton-->
<!--                        view="simple"-->
<!--                        class="options__btn"-->
<!--                        @click="onSelectAll"-->
<!--                    >-->
<!--                        Выбрать все-->
<!--                    </BaseButton>-->
<!--                    <BaseButton-->
<!--                        view="simple"-->
<!--                        class="options__btn"-->
<!--                        @click="onUnselectAll"-->
<!--                    >-->
<!--                        Убрать все-->
<!--                    </BaseButton>-->
<!--                </div>-->
<!--            </template>-->
<!--        </BaseSelectMultiple>-->
        <div class="filter-panel__item-container">
            <BasePreloaderSkeleton v-if="isLoading" />
            <BaseSelectMultipleV2
                class="filter-panel__select"
                view="primary"
                position="left"
                :options="cities"
                :value="selectedCities"
                :canSearch="true"
                :isCloseAfterCheck="false"
                :defaultTitle="cityTitle"
                previewIconComponent="IconBuilding"
                @check="onCheckCities"
            >
                <!-- <template #header>
                    <span class="filter-panel__select-title">Выберите города</span>


                </template> -->
                <!-- <template #footer>
                    <div class="filter-panel__btn-container">
                        <BaseButton
                            class="filter-panel__cancel"
                            view="secondary"
                            form="oval"
                            @click="onClose"
                        >Ок</BaseButton>
                    </div>
                </template> -->
            </BaseSelectMultipleV2>
        </div>
<!--        <BaseSelectMultiple-->
<!--            class="filter-panel__select"-->
<!--            view="primary"-->
<!--            position="left"-->
<!--            :options="allocation"-->
<!--            :value="selectedAllocation"-->
<!--            :canSearch="true"-->
<!--            :defaultTitle="'Тип размещений'"-->
<!--            @check="onCheckCity"-->
<!--        >-->
<!--            <template #header>-->
<!--                <span class="filter-panel__select-title">Тип размещений</span>-->
<!--            </template>-->
<!--            <template #header-options>-->
<!--                <div class="filter-panel__select-header-wrap">-->
<!--                    <BaseButton-->
<!--                        view="simple"-->
<!--                        class="options__btn"-->
<!--                        @click="onSelectAll"-->
<!--                    >-->
<!--                        Выбрать все-->
<!--                    </BaseButton>-->
<!--                    <BaseButton-->
<!--                        view="simple"-->
<!--                        class="options__btn"-->
<!--                        @click="onUnselectAll"-->
<!--                    >-->
<!--                        Убрать все-->
<!--                    </BaseButton>-->
<!--                </div>-->
<!--            </template>-->
<!--        </BaseSelectMultiple>-->
        <div class="filter-panel__item-container">
            <BasePreloaderSkeleton v-if="isLoading" />
            <BaseDropdown
                class="filter-panel__date-wrapper"
                :position="positionDate"
                ref="datepicker"
                :padding="0"
                @changeVisible="isDatepickerOpen = !isDatepickerOpen"
            >
                <template #preview>
                    <BaseButton
                        class="filter-panel-date"
                        view="border"
                        :active="isDatepickerOpen"
                    >
                        <!-- <span class="filter-panel__icon icon-tabler-icon-calendar-minus"></span> -->
                        <IconCalendar class="filter-panel-date__icon-component" />
                        <span class="filter-panel-date__title">{{dateTitle}}</span>
                        <IconArrowV2 class="filter-panel-date__arrow"/>
                    </BaseButton>
                </template>

                <template #content>
                    <DatePicker
                        :isMultiple="true"
                        :accessDates="accessDates"
                        :checkedDates="checkedDates"
                        @cancel="$emit('$closeDropdown')"
                        @approve="onApproveDatepicker"
                    />
                </template>
            </BaseDropdown>
        </div>

        <div class="filter-info">
            <div class="filter-info__wrapper">
                <BasePreloaderSkeleton v-if="isLoading" />
                <BaseButton
                    view="border"
                    class="filter-info-btn"
                    :active="isFilterOpen"
                    @click="onFilterToggle"
                >
                    <IconSettings class="filter-panel-date__icon-component"/>
                    <!-- <span class="filter-panel__icon icon-tabler-icon-filter"></span>
                    <span class="filter-info-btn-text">Фильтр</span> -->
                </BaseButton>
            </div>
        </div>
	</div>
</template>

<script>
// import BaseSelect from '@/components/Base/BaseSelect';
import BaseButton from '@/components/Base/BaseButton'
import BaseDropdown from '@/components/Base/BaseDropdown'
import DatePicker from '@/components/Base/DatePicker'
// import AppChips from './Chips'
import BaseSelectMultipleV2 from "@/components/Base/BaseSelectMultipleV2";
import BasePreloaderSkeleton from '../../components/Base/BasePreloaderSkeleton.vue';

export default {
	name: "FilterPanel",
	components: {
        BaseSelectMultipleV2,
		// BaseSelect,
        BaseButton,
        BaseDropdown,
        DatePicker,
        BasePreloaderSkeleton,
        // AppChips,
        'IconCalendar': () => import('../../components/Icons/IconCalendar.vue'),
        'IconSettings': () => import('../../components/Icons/IconSettings.vue'),
        'IconArrowV2': () => import('../../components/Icons/IconArrowV2.vue'),
	},
    props: {
        chips: {
            type: Array,
            default: () => []
        },
        checkedDates: {
            type: Array,
            default: () => []
        },
        accessDates: {
            type: Array,
            default: () => []
        },
        cities: {
            type: Array,
            default: () => []
        },
        selectedCities: {
            type: Array,
            default: () => []
        },
        isFilterOpen: {
            type: Boolean,
            default: false
        },
        clients: {
            type: Array,
            default: () => []
        },
        selectedClients: {
            type: Array,
            default: () => []
        },
        allocation: {
            type: Array,
            default: () => []
        },
        selectedAllocation: {
            type: Array,
            default: () => []
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },

    /**
     * Данные компонента
     * @property {Array} months -
     * @property {boolean} isDatepickerOpen -
     * @property {Map} monthsFull -
     */
    data() {
        return {
            months: [
				'Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн',
				'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'
			],
            monthsFull: new Map(Object.entries({
                ['Янв']: 'Январь',
                ['Фев']: 'Февраль',
                ['Мар']: 'Март',
                ['Апр']: 'Апрель',
                ['Май']: 'Май',
                ['Июн']: 'Июнь',
				['Июл']: 'Июль',
                ['Авг']: 'Август',
                ['Сен']: 'Сентябрь',
                ['Окт']: 'Октябрь',
                ['Ноя']: 'Ноябрь',
                ['Дек']: 'Декабрь',
            })),
            isDatepickerOpen: false,
            positionDate: 'left'
        }
    },
    computed: {
        /**
         * @returns {String} - заголовок для select
         */
        cityTitle() {
            if (this.selectedCities.length === 0) {
                return 'Города'
            } else if (this.selectedCities.length === 1) {
                const selectedCityId = this.selectedCities[0]
                const selectedCity = this.cities.find(item => String(item.id) === String(selectedCityId))
                return selectedCity.value
            } else {
                return `Выделено: ${this.selectedCities.length}`
            }
        },

        /**
         * @returns {String} - заголовок для select
         */
        // clientTitle() {
        //     return 'Клиенты';
        // },
        /**
         * заголовок для копки даты
         * @returns {String}
         */
        dateTitle() {
            if (this.checkedDates.length === 0) {
                return 'Дата'
            } else {
                let uniqueMonths = []

                this.checkedDates.forEach(item => {
                    uniqueMonths.push(item.month)
                    uniqueMonths = Array.from(new Set(uniqueMonths))
                })
                uniqueMonths = uniqueMonths.map(item => this.months[item-1])
                const result = uniqueMonths.join(', ')

                return this.checkedDates.length === 1 ? this.monthsFull.get(String(result)): `${ result }`;
            }
        },
    },
    created() {
        window.addEventListener("resize", this.onResize);
        this.onResize();
    },

    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
	methods: {
        onResize() {
            this.positionDate = window.innerWidth < 1024 ? 'right' : 'left';
        },
        /**
         * включаю/выключаю фильтр
         */
		onFilterToggle() {
			this.$emit('toggle')
		},
        /**
         * Выбрал города
         * @param {Array} cityIds - id городов
         */
        onCheckCities(cityIds) {
            this.$emit('checkCity', cityIds)
            this.onClose();
        },
        /**
         * Выбрал даты
         * @param {Array} approveDates - выбранные даты в формате {year: 2022, month: 6}
         */
        onApproveDatepicker(approveDates) {
            this.$emit('$closeDropdown')
            this.$emit('approveDatepicker', approveDates);
        },

        onClose() {
            // для прослушки события в компонентк BaseSelect
            this.$emit('$closeSelect')
        },
	},
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default; //#6EC87A !default
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$active-darker-color: 		#1f79d2 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;
$gray-500-color: #AFB0B4;

.filter-panel {
	display: flex;
	align-items: center;
    height: 36px;

    &__item {
        &-container {
            height: 100%;
            position: relative;

            margin-right: 8px;
        }
    }

    &__select-title {
        font-weight: 700;
        font-size: 14px;
    }

    &__select-header-wrap {
        display: flex;
        margin-bottom: 5px;

        .options__btn {
            font-size: 12px;
            font-weight: 500;
            color: $gray-500-color;
            margin-top: 5px;

            &:hover {
                color: #333;
            }

            &:not(:last-child) {
                margin-right: 5px;
            }
        }
    }

    &__btn-container {
        display: flex;
        justify-content: flex-end;
    }

    &__icon {
        font-size: 16px;
        color: #000;

        flex-shrink: 0;
    }

    // & > div:not(&__select) {
    //     margin-left: 10px;
    // }

    &__select {
        height: 100%;
        flex-shrink: 0;

        // margin-left: 10px;

        &--clients {
            margin-left: 0;
        }

        .select-preview {
            padding: 8px 15px;
            border-radius: 10px;
            height: 100%;
        }
    }

    &-date {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 36px;
        width: 180px;
        @media screen and (max-width: 1024px) {
            width: 120px!important;
        }
        text-align: left;

        // padding: 7px 12px;
        padding: 8px 15px;
        // border-radius: 4px;
        border-radius: 10px;

        &__title {
            display: block;
            margin-left: 10px;
            flex-grow: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 110px;

            @media screen and (max-width: 1024px) {
                max-width: 50px;
            }

            color: black;

            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
        }

        &__icon-component {
            width: 16px;
            height: 16px;
        }

        &__arrow {
            width: 8px;
            height: 8px;

            ::v-deep path {
                fill: #AFB0B4;
            }
        }
    }

    &__date-wrapper {
        height: 100%;
    }

    .filter-info {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;

        &__wrapper {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            display: flex;
            position: relative;

            &_has {
                border: 1px solid $primary-color;
            }

            &--resizing {
                align-items: center;
            }
        }

        &__chips-wrapper {
            display: flex;
            width: 100%;

            &--resizing {
                height: 25px;
                overflow: hidden;
            }
        }

        &__chips-place {
            display: flex;
            align-items: center;
            padding: 0 15px;

            &--resizing {
                align-items: flex-start;
            }

            .chips {
                position: relative;
                padding-right: 65px;
            }

            .chips--resizing {
                flex-wrap: wrap;
            }
        }

        &-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            // border-radius: 4px;
            border-radius: 10px;

            // padding: 7px 12px;
            padding: 0;

            &_active {
                background: #fff;
                border-color: $primary-color;
            }

            &-text {
                display: block;
                margin-left: 10px;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        &__border {
            position: absolute;
            top: 0;
            left: -20px;
            width: 300%;
            height: 1px;
            padding: 0;
            background-color: #F2F4F5;
        }
    }

    // @media screen and (max-width: 577px) {
    //     .filter-info {
    //         &__wrapper {
    //             justify-content: flex-end;
    //         }

    //         .filter-info-btn {
    //             padding: 0 13px;
    //         }
    //     }

    // }
}

.filter-panel-date.btn_active,
.filter-info-btn.btn_active {
    border-color: #6EC87A;
}
</style>

<style lang="scss">
.filter-info__chips-place .chips__btn {
    position: absolute;
    top: 0;
    right: 0;
}

.filter-panel {
    .filter-panel__select {
        width: 180px!important;
        @media screen and (max-width: 1024px) {
            width: 120px!important;
        }
        height: auto;

        .select-preview {
            height: 36px;
            padding: 8px 15px;
            border-radius: 10px;
        }
        .select-preview.btn_active {
            border-color: #6EC87A!important;
        }
        .select-preview__label {
            // font-weight: 700;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
        }
    }
    .d-wrap__content-wrapper {
        height: 100%;
    }
}
</style>
