<template>
    <div
        :class="{'kit-panel__scroll': isScroll}"
        ref="kitPanel" 
        class="kit-panel"
        @scroll="onScroll"
        @mousedown="onMouseDown"
        @mouseup="onMouseUp"
        @mousemove="onMouseMove"
    >   
        <template v-if="isLoading">
            <KitPanelItem 
                v-for="(kitItem, index) in kitItemsShadow"
                :key="`${kitItem.id}-${index}`"
                :kitItem="kitItem"
                :kitActions="kitActions"
                :isLoading="isLoading"
                class="kit-panel__item"
            />
        </template>
        <slot name="firstItem"></slot>
        <KitPanelItem
            v-for="(kitItem, index) in kitItems"
            :key="index"
            :kitItem="kitItem"
            :kitActions="kitActions"
            ref="kitPanelItems"
            class="kit-panel__item" 
            @onClickAction="onClickAction"
            @onRename="onRename"
        />
        <slot name="lastItem"></slot>
        <!-- <div 
            v-if="kitItemsUnvisibleCount !== 0" 
            class="kit-panel__more"
        >
            <KitPanelMore 
                :count="kitItemsUnvisibleCount"
                :isStepPrev="isStepPrev"
                :isStepNext="isStepNext"
                @onStep="onStep"
            />
        </div> -->
    </div>
</template>

<script>
/** Компонент панели наборов (размещения, предложения, макеты, проекты и  т.д.) */
import KitPanelItem from './KitPanelItem.vue';
// import KitPanelMore from './KitPanelMore.vue';
import SchemeKitPanel from '../../schemes/SchemeKitPanel';
const {kitItemsShadow} = SchemeKitPanel;
export default {
    name: 'KitPanel',
    components: {
        KitPanelItem,
        // KitPanelMore
    },
    /**
     * Входные данные компонента
     * @property {Array<Object>} kitItems - наборы
     * @property {String} kitItems[].id - идентификатор набора
     * @property {String} kitItems[].name - название набора
     * @property {String|Number} kitItems[].count - колличество сторон набора
     * @property {String|Number} kitItems[].sum - сумма цен набора
     * @property {String} kitItems[].color - цвет набора
     * @property {Array<String>} kitItems[].actionsActive - идентификаторы активных действий набора
     * @property {Array<Object>} kitActions - действия для наборов
     * @property {String} kitActions[].id - идентификатор действия для наборов
     * @property {String} kitActions[].icon - иконка действия для наборов
     * @property {String} kitActions[].emitName - название эмита действия для наборов
     * @property {Boolean} isLoading - условие загрузки
     */
    props: {
        kitItems: {
            type: Array,
            default: () => ([])
        },
        kitActions: {
            type: Array,
            default: () => ([])
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    /**
     * Данные компонента
     * @returns {Object} data - данные компонента
     * @returns {Number} data.kitPanelWidth - ширина панели наборов
     * @returns {Number} data.kitPanelItemWidth - ширина элемента панели наборов
     * @returns {Number} data.kitItemsIndexFirst - текущий первый индекс элементов наборов, с которых идет отображение
     * @returns {Array<Object>} data.kitItemsShadow - набор для скелетной загрузки
     * @returns {String} data.kitItemsShadow[].id - идентификатор набора
     * @returns {String} data.kitItemsShadow[].name - название набора
     * @returns {String|Number} data.kitItemsShadow[].count - колличество сторон набора
     * @returns {String|Number} data.kitItemsShadow[].sum - сумма цен набора
     */
    data: () => ({
        kitPanelWidth: 0,
        kitPanelItemWidth: 180,
        kitItemsIndexFirst: 0,
        kitItemsShadow: [...kitItemsShadow],
        isScroll: false,
        scrollTimerId: null,
        isMouseDown: false
    }),
    computed: {
        /**
         * Колличество видимых элементов наборов
         * @returns {Number}
         */
        kitItemsVisibleCount() {
            return Math.floor((this.kitPanelWidth / (this.kitPanelItemWidth + 10)) - 0.5);
        },

        /**
         * Видимые элементы наборов
         * @returns {Array<Object>}
         */
        kitItemsVisible() {
            return this.kitItems.slice(this.kitItemsIndexFirst, this.kitItemsVisibleCount + this.kitItemsIndexFirst);
        },

        /**
         * Невидимые элементы наборов
         * @returns {Array<Object>}
         */
        kitItemsUnvisible() {
            return this.kitItems.slice(this.kitItemsVisibleCount, this.kitItems.length);
        },

        /**
         * Колличество невидимых элементов наборов
         * @returns {Number}
         */
        kitItemsUnvisibleCount() {
            return this.kitItemsUnvisible.length;
        },

        /**
         * Доступен ли шаг влево
         * @returns {Boolean}
         */
        isStepPrev() {
            return this.kitItemsIndexFirst > 0;
        },

        /**
         * Доступен ли шаг вправо
         * @returns {Boolean}
         */
        isStepNext() {
            return this.kitItemsVisibleCount + this.kitItemsIndexFirst < this.kitItems.length;
        },
    },
    watch: {
        /** 
         * Следим за наборами, вызываем событие изменения ширины экрана, 
         * обновляем индекс первого видимого элемента
         * @param {Array<Object>} kitItems - наборы
         */
        kitItems(kitItems) {
            this.onResizeWindow();

            if (this.kitItemsIndexFirst > kitItems.length)
                this.kitItemsIndexFirst = 0;
        },

        /** 
         * Следим за флагом доступен ли шаг вправо и эмитим событие для пагинации
         * @param {Boolean} val - значение флага
         */
        isStepNext(val) {
            if (!val)
                this.$emit('onPagination');
        }
    },
    created() {
        /** При создании компонента подписываемся на изменение ширины экрана*/
        window.addEventListener('resize', this.onResizeWindow);
        setTimeout(() => this.onResizeWindow(), 100);
    },
    destroyed() {
        /** При уничтожении компонента отписываемся от изменение ширины экрана*/
        window.removeEventListener('resize', this.onResizeWindow);
    },
    methods: {
        /** Событие изменения ширины экрана */
        onResizeWindow() {
            this.updateKitPanelWidth();
        },

        /** Обновить ширину панели с наборами */
        updateKitPanelWidth() {
            const {offsetWidth = 0} = this.$refs.kitPanel;
            this.kitPanelWidth = offsetWidth - 44;
        },

        /** 
         * Событие шага влево или вправо
         * @param {String} stepAction - идентификатор шага (prev|next)
         */
        onStep(stepAction = '') {
            if (this.isStepPrev && stepAction === 'prev')
                this.kitItemsIndexFirst -= 1;
            else if (this.isStepNext && stepAction === 'next')
                this.kitItemsIndexFirst += 1;
        },
        
        /**
         * Событие клика по кнопке действия набора
         * @param {String} kitActionEmitName - название эмита действия
         * @param {String} kitActionId - идентификатор действия
         * @param {String} kitItemId - идентификатор набора
         */
        onClickAction(kitActionEmitName = '', kitActionId = '', kitItemId = '') {
            this.$emit(kitActionEmitName, kitActionId, kitItemId);
        },

        onRename(kitItemNameNew = '', kitItemId = '') {
            this.$emit('onRename', kitItemNameNew, kitItemId);
        },

        onScroll() {
            this.isScroll = true;
            if (this.scrollTimerId !== null)
                clearTimeout(this.scrollTimerId);
            
            this.scrollTimerId = setTimeout(() => {
                this.isScroll = false;
            }, 50);

            this.$refs.kitPanelItems.forEach(kitPanelItem => {
                kitPanelItem.closeMenuContext();
            });
        },

        onMouseDown() {
            this.isMouseDown = true;
        },

        onMouseUp() {
            this.isMouseDown = false;
        },

        onMouseMove(event) {
            if(this.isMouseDown)
                this.$refs.kitPanel.scrollLeft -= event.movementX;
        }
    }
}
</script>

<style lang="scss" scoped>    
    .kit-panel {
        display: flex;
        padding: 0 20px 8px 20px;
        overflow-x: auto;
        cursor: grab;
        user-select: none;

        &::-webkit-scrollbar {
            height: 2px;
        }

        &::-webkit-scrollbar-thumb:horizontal {
            height: 2px;
            display: none;
            &:hover {
                display: block;
            }
        }
        
        &__item:not(:last-child) {
            margin-right: 10px;
        }

        &__more {
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
        }

        &__scroll {
            &::-webkit-scrollbar-thumb:horizontal {
                display: block;
            }
        }
    }
</style>