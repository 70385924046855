<template>
	<div
		v-bind="$attrs"
		:class="getClasses"
		:is="role"
		class="btn"
		@click="$emit('click')"
	>
		<slot />
	</div>
</template>

<script>
export default {
	name: "BaseButton",
	props: {
		view: {
			type: String,
			default: 'primary',
			validator: value => ['primary', 'secondary', 'success', 'cancel', 'border', 'simple', 'green-border'].includes(value)
		},
		form: {
			type: String,
			default: 'square',
			validator: value => ['oval', 'square'].includes(value)
		},
		disabled: {
			type: Boolean,
			default: false
		},
		active: {
			type: Boolean,
			default: false
		},
		role: {
			type: String,
			default: 'button'
		}
	},
	computed: {
		getClasses() {
			return [
				`btn-${this.view}`,
				`btn_${this.form}`,
				{'btn_active': this.active},
				{'btn_disabled': this.disabled}
			]
		}
	}
}
</script>

<style lang="scss" scoped>
$primary-color: 			#f2f2f2 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$active-darker-color: 		#1f79d2 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;
$gray-light-color: #F2F2F2;
$gray-color: #AFB0B4;
$gray-600: #DCDCDC;

.btn {
	border: 1px solid transparent;
	outline: none;
	cursor: pointer;
	text-align: center;
	font: inherit;
	font-weight: 700;
	transition: .2s;
	font-size: 12px;
	padding: 10px 20px;
	white-space: nowrap;

	&_oval {
		border-radius: 1000px;
	}
	&_square {
		border-radius: 10px;
	}
	&_disabled {
		cursor: initial;
		pointer-events: none;
	}

	&-primary {
		background: $primary-color;

		&:hover {
			background: $primary-lighten-color;
		}

		&.btn_active {
			background: #fff;
			border: 1px solid $primary-color;
		}
	}

	&-secondary {
		background: $active-color;
		color: #fff;

		&:hover {
			background: $active-darker-color;
		}

		&.btn_disabled {
            background-color: $primary-color;
            color: $gray-600;
		}
	}

	&-success {
		background: #6BCC9C;
		border-radius: 1000px;
		color: #fff;
	}

	&-cancel {
		background: transparent;
		border: 1px solid $assistant-color;
		color: $assistant-color;
		border-radius: 1000px;

		&:hover {
			color: $active-color;
		}
	}

	&-border {
		background: transparent;
		border: 1px solid $gray-light-color;
        color: $gray-color;
        transition: all .4s ease-out;
		border: 1px solid #F2F2F2;

		&:hover {
			background: $primary-color;
		}
	}

	&-simple {
		color: $assistant-color;
		padding: 0;

		&:hover {
			color: $active-color;
		}
	}

    &-green-border {
        background-color: white;
        border: 1px solid $success-color;
    }
}
</style>
