<!-- eslint-disable no-debugger -->
<template>
    <div class="container-poi">
        <WidgetMapPoi
            title="POI"
            :is-empty="isEmpty"
            :head-menu-items="headerDropdown"
            :items-list="dataGroups"
            :children-list="childrenList"
            :active-items-list="checkedParentList"
            :active-children-list="checkedChildrenList"
            :active-color-item-id="activeColorItemId"
            :dropdown-options="itemsDropdown"
            :children-dropdown-options="childrenItemsDropdown"
            :icons="dataIcons"
            :marker-params="markerParams"
            :radius="radius"
            :widget-poi-config="widgetPoiConfig"
            :item-config="itemConfig"
            :children-config="childrenConfig"
            :is-loading="isLoading"
            :range="range"
            :additionalFilterOptions="additionalFilterOptions"
            :selectedAdditionalFilter="selectedAdditionalFilter"
            :additionalFilterTitle="'Выберите город'"
            class="widget-map-poi"
            @onActionButton="onActionButton"
            @onEmptyContentButton="onEmptyContentButton"
            @onDropdownOption="onDropdownOption"
            @onDropdownChildOption="onDropdownChildOption"
            @onChecked="onCheckedGroup"
            @onCheckedChild="onCheckedPoi"
            @onToggleTitle="onToggleShowPoiList"
            @onTitleChildren="onTitleChildren"
            @onShowColorPanel="onShowColorPanel"
            @onFilterPoiRadius="filterPoiRadius"
            @onCheckAdditionalFilter="onCheckAdditionalFilter"
        />

        <ColorPanel
            v-if="isShowColorPanel && !isShare"
            :title="colorPanelTitle"
            :active-color="activeColor"
            :icons="shortDataIcons"
            :config="colorPanelConfig"
            :is-loading="isLoading"
            class="color-panel"
            @onClose="onCloseColorPanel"
            @onChangeColor="changeColorMarker"
            @onChangeIcon="changeIconMarker"
            @onSubmitFile="fetchPoiFileIcon"
            @onRemoveIcon="fetchRemoveIcon"
        />

        <AddPoiModal
            v-if="isShowAddPoiModal && !isShare"
            :icons="shortDataIcons"
            :is-loading="isLoadingAddList"
            :upload-icon-id="uploadIconId"
            :isLoadingIcon="isLoadingIcon"
            :isLoadingRequest="isWaitingAutoCompleteResponse"
            :requestItems="requestItems"
            :requestId="requestId"
            :requestError="requestError"
            :iconUploadErrors="iconUploadErrors"
            @onCloseModal="isShowAddPoiModal = false"
            @onCreateNewGroup="createNewGroup"
            @onUploadIcon="onUploadIcon"
            @onAutoComplete="onAutoComplete"
        />

        <RealtimeSystemPoi
            :query="queryPoi"
            @poiUpdate="poiUpdate"
        />
    </div>
</template>

<script>
import AddPoiModal from "./AddPoiModal";
import schemeWidgetMap from "../../schemes/SchemeWidgetMap";
import ServicePoi from "../../services/ServicesPoi/ServicePoi";
import ServicePoiSelector from '../../services/ServicesPoi/ServicePoiSelector';
import RealtimeSystemPoi from '../RealtimeSystem/RealtimeSystemPoi.vue';
export default {
    name: "ContainerPoi",
    components: {
        AddPoiModal,
        RealtimeSystemPoi
    },
    /**
     * Данные компонента
     * @property {Number} localCityId - ID текущего города
     * @property {array} dataGroups - Список всех групп
     * @property {array} dataPoints - Список POI
     * @property {array} dataIcons - Список всех иконок POI
     * @property {array} checkedParentList - Список отмеченных чекбосов групп
     * @property {String} radius - Радиус POI
     * @property {Object} markerParams - Цвет или кастомная иконка маркера POI
     * @property {Boolean} isLoading - Показать/скрыть прелоадер
     * @property {Boolean} isLoadingAddList - Показать/скрыть прелоадер списка групп доступных для добавления
     * @property {Boolean} isShowAddPoiModal - Показать/скрыть виджет POI
     * @property {String} activeColorItemId - id poi где активный colorBox
     * @property {String} uploadIconId - id иконки после выполнения UploadIcon
     * @property {Object} widgetPoiConfig - конфиг для widgetPoi
     * @property {Object} itemConfig - конфиг для групп
     * @property {Object} childrenConfig - конфиг для poi
     * @property {Object} colorPanelConfig - конфиг для ColorPanel
     * @property {Object} groupMarkers - буфер для маркеров пои
     * @property {String} childrenGroupId - id группы для аккордеона
     *
     * @property {Boolean} isWaitingAutoCompleteResponse - ожидание ответа от запроса на поиск группы
     * @property {Array} requestItems - найденные похожие подзапросы
     * @property {Number} requestId - id запроса к сервису
     * @property {Number} waitingSocketTimerId - id таймера ожидания сокетов
     * @property {String} Текст ошибки выполнения запроса
     * @property {Array} iconUploadErrors - ошибки валидации иконки
     */
    data: () => ({
        localCityId: null,
        dataGroups: [],
        dataIcons: [],
        checkedParentList: [],
        radius: '100',

        isLoading: false,
        isLoadingAddList: false,
        isShowAddPoiModal: false,
        isLoadingIcon: false,
        activeColorItemId: '',
        activeColor: '',
        uploadIconId: '',
        range: {
            min: '0',
            max: '5000'
        },
        widgetPoiConfig: {
            isShowRadius: true,
            isShowAdditionFilter: true
        },
        itemConfig: {
            isShowColorBox: true,
            isShowCountBox: true,
            isShowDropdown: true,
            isShowChildren: true,
        },
        childrenConfig: {
            isShowColorBox: false,
            isShowCountBox: false,
            isShowDropdown: false,
            isShowChildren: false,
        },
        colorPanelConfig: {
            isShowColorBlock: true,
            isShowIconBlock: true,
            isShowFigureBlock: false,
        },
        isMounted: false,
        additionalFilterOptions: [],
        selectedAdditionalFilter: '',
        groupMarkers: {},
        childrenGroupId: "",
        isWaitingAutoCompleteResponse: false,
        requestItems: [],
        requestId: null,
        waitingSocketTimerId: null,
        requestError: 'По Вашему запросу ничего не найдено',
        iconUploadErrors: [],
        queryPoi: ''
    }),

    /**
     * После рендере компонента получить данные от сервера
     */
    mounted() {
        this.getMountCities();
        if(!this.isShare){
            this.getIcons();
        }else{
            // this.widgetPoiConfig.isShowAdditionFilter = false;
            this.itemConfig.isShowDropdown = false;
            ServicePoi.getGroups(0,(groups) => {
                groups.forEach((group) => {
                    const {iconId = null, key = ""} = group;
                    let src = ServicePoi._getSrcById(iconId, true);
                    if(iconId !== null){
                        this.dataIcons.push({
                            iconId,
                            src
                        })
                        group.src = src;
                    }
                    this.dataGroups. push(group);
                    this.onCheckedGroup(true, key)
                })
                this.isLoading = false;
            })
        }

        this.$emit('changeActiveList', 'poi', this.checkedParentList.length > 0);
        this.$emit('changeActiveList', 'poi', this.checkedChildrenList.length > 0);
    },

    watch: {
        selectedAdditionalFilter(){
            this.checkedParentList.forEach((groupId) => {
                this.$emit("onClearPoiMarkers", groupId);
            })
            this.checkedParentList = [];
            this.groupMarkers = {};
            this.dataGroups = [];
            this.getGroups();
        },
        checkedParentList() {
            this.$emit('changeActiveList', 'poi', this.checkedParentList.length > 0);
        },
        checkedChildrenList() {
            this.$emit('changeActiveList', 'poi', this.checkedChildrenList.length > 0);
        }
    },
    computed: {
        isShare() {
            const {query = {}} = this.$route;
            const {shareToken = null} = query;
            return shareToken !== null;
        },

        colorPanelTitle(){
            if(!this.activeColorItemId){
                return "";
            }
            return this.dataGroups.filter(dataGroup => this.activeColorItemId === dataGroup.key)[0].name
        },
        isShowColorPanel(){
            return this.activeColorItemId !== "";
        },
        childrenList(){
            if(!this.childrenGroupId){
                return [];
            }
            let dataPoints = this.groupMarkers[this.childrenGroupId] || [];
            return dataPoints.map((dataPoint) => {
                const {extraData = {}} = dataPoint;
                const {id = "", groupId: key = "", address = ""} = extraData;
                return {
                    id,
                    key,
                    description: address
                };
            })
        },
        // childrenListAll() {
        //     if(!this.childrenGroupId){
        //         return [];
        //     }

        //     const groupIdsMarkers = Object.keys(this.groupMarkers).filter(groupId => String(groupId) !== String(this.childrenGroupId));
        //     return groupIdsMarkers.reduce((childrenListAll, groupId) => {
        //         const markers = this.groupMarkers[groupId] ?? [];
        //         markers.forEach(marker => {
        //             const {extraData = {}} = marker;
        //             const {id = '', groupId = '', address = ""} = extraData;
        //             childrenListAll.push({id: String(id), key: String(groupId), description: String(address)});
        //         });

        //         return childrenListAll;
        //     }, []);
        // },
        // activeChildrenList() {
        //     return Object.values(this.groupMarkers).reduce((activeChildrenList, markers) => {
        //         markers.forEach(marker => {
        //             const {extraData = {}} = marker;
        //             const {id = '', groupId = '', isActive = true} = extraData;
        //             if (isActive)
        //                 activeChildrenList.push({id: String(id), key: String(groupId)});
        //         });

        //         return activeChildrenList;
        //     }, []);
        // },
        checkedChildrenList(){
            if(!this.childrenGroupId){
                return [];
            }
            let dataPoints = this.groupMarkers[this.childrenGroupId] || [];
            return dataPoints.reduce((checkedPois, dataPoint) => {
                const {extraData = {}} = dataPoint;
                const {id = "", isActive = true, groupId = ""} = extraData;
                if(isActive){
                    checkedPois.push({id, key: groupId});
                }
                return checkedPois;
            }, []);
        },
        /**
         * Показать заглушку если нет групп в списке
         */
        isEmpty() {
            return !this.isLoading && this.dataGroups.length === 0;
        },

        /**
         *
         */
        markerParams(){
            return this.dataGroups.map((dataGroup) => {
                return {
                    key: dataGroup.key,
                    color: dataGroup.color,
                    iconId: dataGroup.iconId,
                    src: dataGroup.src,
                    figure: 'square'
                };
            })
        },

        /**
         * Элементы Dropdown
         */
        headerDropdown() {
            const { headerDropdownItems = [] } = schemeWidgetMap.containerPoi;
            return this.isShare ? [] : headerDropdownItems;
        },

        /**
         * Элементы Dropdown
         */
        itemsDropdown() {
            const { groupMenuItems = [] } = schemeWidgetMap.containerPoi;
            return groupMenuItems;
        },

        /**
         * Элементы Dropdown
         */
        childrenItemsDropdown() {
            const { poiMenuItems = [] } = schemeWidgetMap.containerPoi;
            return poiMenuItems;
        },

        /**
         * Длинна массива отмеченных групп пои
         */
        checkedParentListLength() {
            return this.checkedParentList.length;
        },

        /**
         * Длинна массива списка групп пои
         */
        dataGroupsLength() {
            return this.dataGroups.length;
        },

        /**
         * Обрезать массив иконок до последних 5
         */
        shortDataIcons() {
            if (this.dataIcons.length > 0) {
                const sortIcons = [...this.dataIcons]
                    .sort((prevIcon, nextIcon) => Number(prevIcon.iconId) - Number(nextIcon.iconId))
                    .slice(-5);
                return sortIcons;
            }
            return [];
        },
    },
    methods: {
        /**
         * Клик по кнопке в компоненнте BaseActionButtons
         * @param {String} emitName - Имя события кнопки
         */
        onActionButton(emitName) {
            console.log('onActionButton', emitName);
            if(emitName === 'unselectedAll') {
                this.checkedParentList.forEach((groupId) => {
                    this.$emit("onClearPoiMarkers", groupId);
                    if (!this.isShare)
                        ServicePoiSelector.unsetPoiGroupChecked(this.selectedAdditionalFilter, groupId);
                })
                this.checkedParentList = [];
            }
            if (emitName === 'reset') {
                this.$emit("onFilterPoiRadius", 0);
                this.radius = "100";
            }
        },

        /**
         * Клик по кнопке в компоненте BaseEmpty
         */
        onEmptyContentButton() {
            this.isShowAddPoiModal = true;
        },

        /**
         * Клик по пункту в dropdown группы
         * @param {string} methodName - название функции обработчика события
         * @param {string} groupId - id элемента по которому произошел клик
         */
        onDropdownOption(methodName, groupId) {
            if (methodName === 'onAddNewGroup' ) {
                this.isShowAddPoiModal = true;
            }
            console.log('onDropdownOption', methodName, groupId);
            if (methodName === 'removeGroupPoi') {
                this.removePoiGroup(groupId);
                if (!this.isShare)
                    ServicePoiSelector.unsetPoiGroupChecked(this.selectedAdditionalFilter, groupId);
            }
        },

        /**
         * Удаление группы пои
         *
         * @param {String} groupId
         */
        removePoiGroup(groupId){
            this.isLoading = true;
            ServicePoi.removeGroup(groupId, () => {
                this.$emit("onClearPoiMarkers", groupId);
                this.dataGroups = this.dataGroups.filter((dataGroup) => {
                    const {key = ""} = dataGroup;
                    return key !== groupId;
                });
                let groupMarkers = {...this.groupMarkers};
                if(Object.keys(groupMarkers).includes(groupId)){
                    delete groupMarkers[groupId];
                }
                this.isLoading = false;
            });
        },

        /**
         * Клик по пункту в dropdown poi
         * @param {string} methodName - название функции обработчика события
         * @param {string} childId - id элемента по которому произошел клик
         */
        // eslint-disable-next-line no-unused-vars
        onDropdownChildOption(methodName, childId) {
            console.log('onDropdownChildOption', methodName, childId);
            if (methodName === 'removePoi') {
                // this.removePoi(childId);
            }
        },

        /**
         * Обработка клика по чекбоксу poi
         * @async
         * @param {Boolean} isChecked - включен/выключен чекбокс
         * @param {String} poiId - id poi
         */
        async onCheckedPoi(isChecked, poiId) {
            this.checkPoi(isChecked, poiId, this.childrenGroupId, true);
        },

        checkPoi(isChecked, poiId, childrenGroupId, isUpdateStore = false) {
            let groupMarkers = {...this.groupMarkers};
            let dataPoints = groupMarkers[childrenGroupId] || [];
            let markerIndex = dataPoints.findIndex((dataPoint) => {
                const {extraData = {}} = dataPoint;
                const {id = ""} = extraData;
                return id === poiId;
            })
            let updatingMarker = dataPoints[markerIndex];
            updatingMarker.extraData.isActive = isChecked;
            dataPoints.splice(markerIndex, 1, updatingMarker);
            groupMarkers[childrenGroupId] = [...dataPoints];
            this.groupMarkers = {...groupMarkers};
            this.$emit("onTogglePoiMarker", updatingMarker);

            const isCheckedGroup = this.checkedParentList.includes(String(childrenGroupId));
            const dataPointsChecked = dataPoints.filter(dataPoint => {
                const {extraData = {}} = dataPoint;
                const {isActive = false} = extraData;
                return Boolean(isActive);
            });

            if (!isCheckedGroup && isChecked)
                this.checkedParentList.push(childrenGroupId);
            else if (isCheckedGroup && dataPointsChecked.length === 0) {
                const groupCheckedIndex = this.checkedParentList.findIndex(groupId => String(groupId) === String(childrenGroupId));
                this.checkedParentList.splice(groupCheckedIndex, 1);
            }

            if (isUpdateStore && !this.isShare) {
                if (isChecked)
                    ServicePoiSelector.setPoiMarkerChecked(this.selectedAdditionalFilter, childrenGroupId, poiId);
                else
                    ServicePoiSelector.unsetPoiMarkerChecked(this.selectedAdditionalFilter, childrenGroupId, poiId);
            }
        },

        /**
         * Обработка клика по названию poi
         * @param {String} childId - id poi
         */
        onTitleChildren(childId) {
            console.log('onTitleChildren', childId);
        },

        /**
         * Клик по colorLabel
         * @param {String} itemId - id элемента
         */
        onShowColorPanel(itemId = '') {
            this.activeColorItemId = itemId;
        },

        /**
         * Фильтрация размещений по выбранным точкам poi
         * @param {String} radius - радиус поиска poi
         **/
        filterPoiRadius(radius){
            this.radius = String(radius);
            this.$emit("onFilterPoiRadius", Number(radius));
        },

        /**
         * Смена города
         *
         * @param {Object} city
         */
        onCheckAdditionalFilter(city){
            const {id} = city;
            this.selectedAdditionalFilter = id;
        },

        /**
         * Клик по закрыть ColorPanel
         */
        onCloseColorPanel() {
            this.activeColorItemId = '';
        },

        /**
         * Изменить цвет маркера
         * @param {String} color - Цвет маркера
         */
        changeColorMarker(color = '000000') {
            this.isLoading = true;
            ServicePoi.updateGroup({group_id: Number(this.activeColorItemId), color}, () => {
                ServicePoi.unlinkIcon(Number(this.activeColorItemId), () => {
                    this.setColorAndUnlinkGroup(this.activeColorItemId, color);
                    this.isLoading = false;
                })
            });
        },

        setColorAndUnlinkGroup(groupId, color){
            this.dataGroups.filter(dataGroup => dataGroup.key === groupId)
                .forEach((dataGroup) => {
                    dataGroup.color = color;
                    dataGroup.src = "";
                    dataGroup.iconId = "";
                });
            if(Object.keys(this.groupMarkers).includes(groupId)){
                let markers = {...this.groupMarkers};
                markers[this.activeColorItemId].forEach((marker) => {
                    marker.fill_color = color;
                    marker.iconType = 'poi';
                    marker.src = "";
                })
                this.groupMarkers = {...markers};
                this.$emit("onUpdateColorMarkers", groupId, color);
            }
        },

        /**
         * Изменить иконку маркера
         * @param {Object} params - Параметры иконки маркера
         */
        changeIconMarker(params = {}) {
            const {iconId, src} = params;
            this.isLoading = true;
            ServicePoi.linkIcon(this.activeColorItemId, iconId, () => {
                this.dataGroups.filter(dataGroups => dataGroups.key === this.activeColorItemId)
                    .forEach((dataGroups) => {
                        dataGroups.src = src;
                        dataGroups.iconId = iconId;
                    });
                if(Object.keys(this.groupMarkers).includes(this.activeColorItemId)){
                    let markers = {...this.groupMarkers};
                    markers[this.activeColorItemId].forEach((marker) => {
                        marker.src = src;
                        marker.iconType = "poiIcon";
                    })
                    this.groupMarkers = {...markers};
                    this.$emit("onUpdateIconMarkers", this.activeColorItemId, src);
                }
                this.isLoading = false;
            })

        },

        /**
         * Удалить иконку из списка
         * @param {object} params - параметры удаляемой иконки
         */
        async fetchRemoveIcon(params = {}) {
            const {iconId} = params;
            this.isLoading = true;
            ServicePoi.removeIcon(iconId, () => {
                this.dataIcons = this.dataIcons.filter((dataIcon) => String(dataIcon.iconId) !== String(iconId));
                this.dataGroups.filter(dataGroup => String(dataGroup.iconId) === String(iconId) && iconId)
                    .forEach((dataGroup) => {
                        let {color, id} = dataGroup;
                        this.setColorAndUnlinkGroup(id, color);
                    });
                this.isLoading = false;
            })
        },

        /**
         * Добавление группы
         * @async
         * @param {Object} requestBody - параметры новой группы
         */
        async createNewGroup(requestBody) {
            this.isLoading = true;
            this.isShowAddPoiModal = false;
            requestBody.city_id = this.selectedAdditionalFilter;
            ServicePoi.createGroup(requestBody, (group, newMarkers, pageCount) => {
                const {key: groupId = ""} = group;
                this.dataGroups.push(group);
                this.checkedParentList.push(groupId);
                this.appendAndDrawNewMarkers(groupId, newMarkers);
                this.setPoiGroupChecked(groupId);
                if(pageCount > 1){
                    this.getPoiMarkers(groupId, 2,(markers) => {
                        this.appendAndDrawNewMarkers(groupId, markers);
                        this.setPoiGroupChecked(groupId);
                    });
                }
                this.isLoading = false;
            })
        },

        /**
         *
         */
        appendAndDrawNewMarkers(groupId, markers){
            if(!Object.keys(this.groupMarkers).includes(groupId)){
                this.groupMarkers[groupId] = [];
            }
            this.groupMarkers[groupId] = [...this.groupMarkers[groupId], ...markers];
            this.$emit("onDrawPoiMarkers", markers)
        },

        /**
         * Загрузили с ПК иконку для маркера POI
         * @async
         * @param {File} fileIcon - цвет или id картинки для маркера POI
         */
        async fetchPoiFileIcon(fileIcon = null) {
            this.onUploadIcon(fileIcon, true);
        },

        /**
         * Выбор иконки из списка
         * @property {File} file - выбранная иконка
         * @property {Boolean} isAlertErrors
         */
        async onUploadIcon(file, isAlertErrors = false) {
            this.isLoadingIcon = true;
            this.isLoading = true;
            ServicePoi.uploadIcon(file, (icon) => {
                this.dataIcons.push(icon)
                this.uploadIconId = icon.iconId;
                this.isLoadingIcon = false;
                this.isLoading = false;
                this.iconUploadErrors = [];
            }, (errors) => {
                this.isLoadingIcon = false;
                this.isLoading = false;
                let iconErrors = errors["icon"] ?? [];
                if(!isAlertErrors){
                    this.iconUploadErrors = iconErrors;
                }else{
                    alert(iconErrors.join(', '))
                }
            })
        },

        /**
         * Автозаполнение похожих групп для запроса
         * @param {String} value - поисковой запрос
         */
        onAutoComplete(value){
            if(!value){
                this.queryPoi = '';
                return false;
            }
            this.queryPoi = String(value);
            this.requestId = null;
            this.requestItems = [];
            this.isWaitingAutoCompleteResponse = true;
            ServicePoi.autoComplete(value, this.selectedAdditionalFilter, (result) => {
                const {requestId = null, resultItems = []} = result;
                if(requestId === null){
                    this.waitingSocketTimerId = setTimeout(() => {
                        this.waitingSocketTimerId = null;
                        this.requestError = "По Вашему запросу ничего не найдено";
                        this.isWaitingAutoCompleteResponse = false;
                    }, 60000);
                }else{
                    this.requestId = requestId;
                    const requestItemsIds = this.requestItems.map(requestItem => String(requestItem?.id));
                    const resultItemsFiltered = resultItems.filter(resultItem => !requestItemsIds.includes(resultItem?.id));
                    // this.requestItems = resultItems;
                    this.requestItems.push(...resultItemsFiltered);
                    this.isWaitingAutoCompleteResponse = false;
                }
            }, (responseErrors) => {
                this.isWaitingAutoCompleteResponse = false;
                this.requestError = "По Вашему запросу ничего не найдено";
                let keys = Object.keys(responseErrors)
                if(keys.length){
                    this.requestError = Array.isArray(responseErrors[keys[0]])
                        ? responseErrors[keys[0]][0] : responseErrors[keys[0]];
                }
            });
        },

        poiUpdate(poiItems = []) {
            if(this.requestId === 0){
                return false;
            }
            if(this.requestId === null){
                this.requestId = 0;
                ServicePoi.autoComplete(this.queryPoi, this.selectedAdditionalFilter, (result) => {
                    const {requestId = null} = result;
                    this.requestId = requestId;
                })
            }
            this.requestItems = poiItems.reduce((requestItems, item) => {
                const requestItemIndex = requestItems.findIndex((requestItem) => String(requestItem?.id) === String(item?.id));

                if (requestItemIndex !== -1)
                    requestItems.splice(requestItemIndex, 1, item);
                else
                    requestItems.push(item);

                return requestItems;
            }, [...this.requestItems]);

            this.isWaitingAutoCompleteResponse = false;
        },

        /**
         * Получение городов
         */
        async getMountCities(){
            this.isLoading = true;
            ServicePoi.getCities((cities) => {
                this.additionalFilterOptions = cities;
                this.selectedAdditionalFilter = String(cities[0].id);
                this.isLoading = false;
            });
        },

        /**
         * Получение групп для текущего города
         */
        async getGroups(){
            this.isLoading = true;
            ServicePoi.getGroups(this.selectedAdditionalFilter, (groups) => {
                this.dataGroups = groups;
                this.isLoading = false;

                if (!this.isShare) {
                    const poiChecked = ServicePoiSelector.getPoiGroupsChecked(this.selectedAdditionalFilter);
                    const poiGroupdsIdsChecked = Object.keys(poiChecked);
                    poiGroupdsIdsChecked.forEach(groupId => {
                        this.getPoiMarkers(groupId, 1, (markers) => {
                            const poiMarkersIdsChecked = poiChecked[groupId] ?? [];
                            this.groupMarkers[groupId] = [...this.groupMarkers[groupId], ...this.updateActivity(markers, false)];
                            markers.forEach(marker => {
                                const {extraData = {}} = marker;
                                const {groupId: markerGroupId = '', id: markerId = ''} = extraData;

                                if (String(markerGroupId) === String(groupId) && poiMarkersIdsChecked.includes(String(markerId)))
                                    this.checkPoi(true, String(markerId), String(groupId), false);
                            })
                        });
                        this.checkedParentList.push(String(groupId));
                    });
                }
            })
        },

        /**
         * Получение групп для текущего города
         */
        async getIcons(){
            ServicePoi.getIconList((icons) => {
                this.dataIcons = icons ?? [];
            })
        },

        /**
         * Обработка клика по названию группы
         * Получение точек данной группы с бекенда
         * @async
         * @param {String} groupId - key группы
         */
        onToggleShowPoiList(groupId) {
            console.log(groupId);
            if(!Object.keys(this.groupMarkers).includes(groupId)){
                this.groupMarkers[groupId] = [];
                this.getPoiMarkers(groupId, 1,(markers) => {
                    this.groupMarkers[groupId] = [...this.groupMarkers[groupId], ...this.updateActivity(markers, false)];
                    this.childrenGroupId = String(groupId);
                });
            }else{
                this.childrenGroupId = String(this.childrenGroupId) === String(groupId) ? '' : String(groupId);
            }
        },

        /**
         * Обработка клика по чекбоксу группы
         * @async
         * @param {Boolean} isChecked - включен/выключен чекбокс
         * @param {String} groupId - key группы
         */
        async onCheckedGroup(isChecked, groupId) {
            if (isChecked){
                this.checkedParentList.push(groupId)
                this.drawMarkers(groupId, () => {
                    this.setPoiGroupChecked(groupId);
                })
            }else {
                this.checkedParentList = this.checkedParentList
                    .filter(competitorParentActiveItem => competitorParentActiveItem !== groupId);
                this.updateActivityByGroupId(groupId, false);
                this.$emit("onClearPoiMarkers", groupId);

                if (!this.isShare)
                    ServicePoiSelector.unsetPoiGroupChecked(this.selectedAdditionalFilter, groupId);
            }

        },

        setPoiGroupChecked(groupId = '', ) {
            if (!this.isShare) {
                const poiMarkers = this.groupMarkers[groupId] ?? [];
                const poiMarkersIds = poiMarkers.map(poiMarker => String(poiMarker?.id));
                ServicePoiSelector.setPoiGroupChecked(this.selectedAdditionalFilter, groupId, poiMarkersIds);
            }
        },

        /**
         * Отрисовка маркеров
         *
         * @param {String} groupId - id группы пои
         */
        drawMarkers(groupId, actionAfter = () => {}){
            if(Object.keys(this.groupMarkers).includes(groupId)){
                this.updateActivityByGroupId(groupId, true);
                actionAfter();
                this.$emit("onDrawPoiMarkers", this.groupMarkers[groupId]);
            }else{
                this.getPoiMarkers(groupId, 1,(markers) => {
                    this.appendAndDrawNewMarkers(groupId, markers);
                    actionAfter();
                });
            }
        },

        /**
         * Выбор иконки из списка
         * @property {String} groupId - выбранная иконка
         * @property {Number} defaultPage
         * @property {Function} actionAfter
         */
        async getPoiMarkers(groupId, defaultPage = 1, actionAfter = () => {}){
            this.isLoading = true;
            const group = this.findGroup(groupId);
            this.groupMarkers[groupId] = [];
            ServicePoi.getPoints(group, defaultPage, (markers, pageCount) => {
                actionAfter(markers);
                let countUploaded = defaultPage;
                for (var page = defaultPage + 1; page <= pageCount; page++){
                    ServicePoi.getPoints(group, page, (stepMarkers,) => {
                        actionAfter(stepMarkers);
                        countUploaded++;
                        if(countUploaded >= pageCount){
                            this.isLoading = false;
                        }
                    });
                }
                if(defaultPage >= pageCount){
                    this.isLoading = false;
                }
            });
        },

        /**
         *
         * @param {Array} markers - маркеры группы
         * @param {Boolean} isActive - флаг активности маркера
         */
        updateActivity(markers, isActive){
            markers.forEach((marker) => {
                marker.extraData.isActive = isActive;
            })
            return markers;
        },

        /**
         * Изменение активности группы маркеров
         *
         * @param {Boolean} isActive - флаг активности маркера
         * @param {String} groupId - id группы маркеров
         */
        updateActivityByGroupId(groupId, isActive){
            let markers = {...this.groupMarkers};
            let groupMarkers = markers[groupId] || [];
            markers[groupId] = this.updateActivity(groupMarkers, isActive);
            this.groupMarkers = {...markers};
        },

        /**
         * Поиск объекта с инфо о группе
         * @property {String} groupId - выбранная иконка
         */
        findGroup(groupId){
            return this.dataGroups.filter(dataGroup => dataGroup.key === groupId)[0];
        }
    }
}
</script>

<style lang="scss" scoped>
.container-poi {
    display: flex;
    align-items: flex-start;

    .widget-map-poi {
        margin-left: 10px;
    }

    .color-panel {
        margin-left: 10px;
    }
}
</style>

