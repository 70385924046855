import ServiceApi from '../ServiceApi';
/** Сервис для работы с группвми цен (макеты, проекты, предложения) */
export default class ServicePriceGroup {
    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = 'apiMap';

    /**
     * Типы групп цен в интерпретации бэка
     * @private
     * @static
     * @type {Object}
     * @property {String} _pgTypes
     */
    static _pgTypes = {
        ap: 1,
        maket: 3,
        project: 4,
        proposal: 2
    };

    /**
     * Маршруты для запросов
     * @private
     * @static
     * @type {Object}
     * @property {String} filterMain - маршрут для получения фильтра
     */
    static _requestRouts = {
        base: '/price_group',
        create: '/price_group/create',
        update: '/price_group/update',
        delete: '/price_group/delete',
        massDelete: '/price_group/mass-delete'
    };

    /**
     * Получить существующие группы
     * @public
     * @static
     * @async
     * @param {Number} type - тип группы цен (предложение, макет, проект)
     * @param {Function} actionAfter - событие, срабатывающее после получения групп
     */
    static async getPgList(type, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.get(this._microserviceName, this._requestRouts.base + "/" + this._pgTypes[type]);
            const {data: pgList = []} = res;
            actionAfter(pgList);
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Создание группы
     * @public
     * @static
     * @async
     * @param {String} name - название
     * @param {Number} type - тип группы цен (предложение, макет, проект)
     * @param {Array<String>} priceIds - массив id цен
     * @param {Function} actionAfter - событие, срабатывающее после получения групп
     */
    static async create(name, type, priceIds, actionAfter = () => {}) {
        try {
            const refreshType = this._pgTypes[type];
            const requestBody = {
                price_group: {
                    name,
                    type: refreshType,
                    price_ids: priceIds
                }
            };
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.create, requestBody);
            const {data = []} = res;
            actionAfter(data);
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Обновление группы
     * @public
     * @static
     * @async
     * @param {Number} pg_id - id группы цен (предложение, макет, проект)
     * @param {Array<String>} priceIds - массив id цен
     * @param {Function} actionAfter - событие, срабатывающее после получения групп
     */
    static async update(pg_id, priceIds, actionAfter = () => {}) {
        try {
            const requestBody = {
                price_group: {
                    pg_id,
                    action: "link",
                    price_ids: priceIds
                }
            };
            const res = await ServiceApi.put(this._microserviceName, this._requestRouts.update, requestBody);
            const {data = []} = res;
            actionAfter(data);
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Массовое удаление цен
     * @public
     * @static
     * @async
     * @param {Array<String>} priceIds - массив id цен
     * @param {Function} actionAfter - событие, срабатывающее после получения групп
     */
    static async massDelete(priceIds, actionAfter = () => {}) {
        try {
            const requestBody = {
                price_group: {
                    price_ids: priceIds
                }
            };
            await ServiceApi.post(this._microserviceName, this._requestRouts.massDelete, requestBody);
            actionAfter();
        } catch(error) {
            console.log(error);
        }
    }
}
