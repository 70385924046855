import { render, staticRenderFns } from "./ContainerCompetitor.vue?vue&type=template&id=270b3033&scoped=true&"
import script from "./ContainerCompetitor.vue?vue&type=script&lang=js&"
export * from "./ContainerCompetitor.vue?vue&type=script&lang=js&"
import style0 from "./ContainerCompetitor.vue?vue&type=style&index=0&id=270b3033&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "270b3033",
  null
  
)

export default component.exports