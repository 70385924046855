/**
 * Схема для панели наборов
 * @property {Array<Object>} SchemeKitPanel.kitActions - действия для наборов
 * @property {String} SchemeKitPanel.kitActions[].id - идентификатор действия для наборов
 * @property {String} SchemeKitPanel.kitActions[].icon - иконка действия для наборов
 * @property {String} SchemeKitPanel.kitActions[].iconComponent - иконка действия для наборов в виде компонента
 * @property {String} SchemeKitPanel.kitActions[].emitName - название эмита действия для наборов
 * @property {String} SchemeKitPanel.kitActions[].isGuest - флаг доступности действия для шары
 * @property {Array<Object>} SchemeKitPanel.kitItemsShadow - набор для скелетной загрузки
 * @property {String} SchemeKitPanel.kitItemsShadow[].id - идентификатор набора
 * @property {String} SchemeKitPanel.kitItemsShadow[].name - название набора
 * @property {String|Number} SchemeKitPanel.kitItemsShadow[].count - колличество сторон набора
 * @property {String|Number} SchemeKitPanel.kitItemsShadow[].sum - сумма цен набора
 */
const SchemeKitPanel = {
    kitActions: [
        {
            id: 'markers',
            icon: 'icon-tabler-icon-map-pin',
            iconComponent: "",
            emitName: 'onToggleMarkers',
            desc: 'Метки на карте'
        },
        {
            id: 'table',
            icon: 'icon-tabler-icon-border-all',
            iconComponent: '',
            emitName: 'onToggleTable',
            desc: 'Таблица'
        },
        {
            id: 'summary',
            icon: '',
            iconComponent: "IconInfo",
            emitName: 'onToggleSummary',
            desc: 'Сводные данные'
        },
        // {
        //     id: 'admetrix',
        //     icon: 'icon-tabler-icon-rocket',
        //     iconComponent: "",
        //     emitName: 'onToggleAdmetrix'
        // },
        {
            id: 'share',
            icon: 'icon-tabler-icon-link',
            iconComponent: "",
            emitName: 'onShare',
            isGuest: false,
            isHasLabelChecked: true,
            desc: 'Поделиться ссылкой'
        },
        {
            id: 'export',
            icon: 'icon-tabler-icon-file-download',
            iconComponent: "",
            emitName: 'onExport',
            desc: 'Скачать Excel'
        },
        {
            id: 'more',
            icon: '',
            iconComponent: 'IconHamburger',
            emitName: 'onMore',
            desc: 'Больше',
            isGuest: false,
            isMenuContext: true,
            menuContextItems: [
                {
                    id: 'admetrix',
                    iconComponent: 'IconRocket',
                    emitName: 'onToggleAdmetrix',
                    desc: 'Рассчитать Admetrix'
                },
                {
                    id: 'delete',
                    iconComponent: 'IconTrash',
                    emitName: 'onRemoveKit',
                    desc: 'Удалить'
                }
            ],
        }
    ],
    kitItemsShadow: [
        {
            id: '666',
            name: 'Fucking Oohdesk',
            sum: '666666',
            count: '666'
        },
        {
            id: '777',
            name: 'Fucking Oohdesk',
            sum: '666666',
            count: '666'
        },
        {
            id: '888',
            name: 'Fucking Oohdesk',
            sum: '666666',
            count: '666'
        }
    ]
};

export default SchemeKitPanel;
