<template>
    <div
        v-if="params.photoMiddle"
        class="photo"
        :style="{'background-image' : 'url(' + params.photoMiddle + ')'}"
    >
        <h3 class="photo__title" @mousedown="$emit('mouseDown', $event)">Фото</h3>

<!--        <div class="photo__slider-nav-wrap">-->
<!--            <BaseButtonIcon-->
<!--                class="photo__button"-->
<!--                tooltip-text="Предыдущее фото"-->
<!--                icon-name="tabler-icon-chevron-left"-->
<!--            />-->
<!--            <BaseButtonIcon-->
<!--                class="photo__button"-->
<!--                tooltip-text="Следующее фото"-->
<!--                icon-name="tabler-icon-chevron-right"-->
<!--            />-->
<!--        </div>-->

<!--        <div class="photo__actions-wrap">-->
<!--            <BaseButtonIcon-->
<!--                class="photo__button"-->
<!--                tooltip-text="Карточка конструкции на сайте оператора"-->
<!--                icon-name="tabler-icon-copy"-->
<!--            />-->
<!--            <BaseButtonIcon-->
<!--                class="photo__button"-->
<!--                tooltip-text="Фотоотчет по периодам"-->
<!--                icon-name="tabler-icon-copy"-->
<!--            />-->
<!--            <BaseButtonIcon-->
<!--                class="photo__button"-->
<!--                tooltip-text="Дата загрузки фото: 09.11.2019"-->
<!--                icon-name="tabler-icon-copy"-->
<!--            />-->
<!--        </div>-->
    </div>
</template>

<script>

export default {
    name: "Photo",
    props: {
        params: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        // BaseButtonIcon,
    },

    computed: {

    },
}
</script>

<style lang="scss" scoped>
$color-border: #F2F3F5;
$color-gray-300: #AFB0B4;
$color-gray: #b9bbc2;

.photo {
    position: relative;
    min-height: 160px;
    background-color: #fff;
    /*background-image: url("https://loremflickr.com/640/360");*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    border: 1px solid $color-border;

    &__title {
        position: absolute;
        top: 8px;
        left: 8px;

        display: block;
        width: max-content;
        font-size: 12px;
        font-weight: 400;
        color: $color-gray-300;
        background-color: #fff;
        padding: 6px 12px;
        border-radius: 4px;
        cursor: move;
    }

    &__slider-nav-wrap {
        display: flex;

        position: absolute;
        top: 8px;
        right: 8px;
    }

    &__button {
        &:not(:last-child) {
            margin-right: 2px;
        }
    }

    &__slider-icon {
        font-size: 12px;
        font-weight: 800;
        color: #000;
    }

    &__actions-wrap {
        display: flex;

        position: absolute;
        bottom: 8px;
        right: 8px;
    }

}
</style>

