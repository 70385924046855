<template>
    <div class="maket">
        <h3 class="maket__title" @mousedown="$emit('mouseDown', $event)">Макеты</h3>

        <ul class="maket__list">
            <li
                v-for="(item, index) in params.layoutList"
                :key="index"
                class="maket__list-item"
            >
                <div class="maket__text">{{item.name}}</div>

                <BaseDropdownMenu
                    :preview-style="getStylePreviewMenu"
                    preview-form="circle"
                    :preview-is-gray="true"
                    :options="getMenuOptions"
                    @check="removeGroup(item.id)"
                />
            </li>
        </ul>
    </div>
</template>

<script>
import BaseDropdownMenu from "@/components/Base/BaseDropdownMenu";

export default {
    name: "Maket",
    components: {
        BaseDropdownMenu,
    },
    props: {
        params: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        getMenuOptions() {
            return [
                {value: 'Отвязать макет'}
            ];
        },

        getStylePreviewMenu() {
            return {
                border: 'none',
                width: '25px',
                height: '25px',
            }
        },
    },
    methods: {
        /**
         * Удаление группы
         * @param groupId
         */
        removeGroup(groupId){
            this.$emit('removeGroup', {priceId:this.params.priceId, groupId:groupId})
        }
    }
}
</script>

<style lang="scss" scoped>
$color-border: #F2F3F5;
$color-gray-300: #AFB0B4;
$color-gray-900: #F2F2F2;

.maket {
    background: #fff;
    padding: 15px 20px 20px 20px;
    border-radius: 10px;
    border: 1px solid $color-border;

    &__title {
        width: max-content;
        font-size: 12px;
        font-weight: 400;
        color: $color-gray-300;
        cursor: move;
        padding: 5px 0;

        margin-bottom: 10px;
    }

    &__list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-radius: 10px;
        background-color: $color-gray-900;

        padding: 9px 12px 8px 12px;
        margin-bottom: 5px;
    }

    &__text {
        font-size: 12px;
        letter-spacing: 0;
    }

    &__menu-btn {
        width: 25px;
        height: 25px;
        margin-left: 10px;
        padding: 5px;
        border-radius: 100%;
        background-color: inherit;

        &:hover, &:focus {
            background-color: #fff;
        }
    }
}
</style>
