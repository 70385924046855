import ServiceApi from "../ServiceApi";
import ServiceFilter from "../ServicesFilter/ServiceFilter";
import UtilDate from "../../utils/UtilDate";
/** Сервис для работы с конкурентами */
export default class ServiceCompetitor {

    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = 'apiMap';

    /**
     * Маршруты для запросов
     * @private
     * @static
     * @type {Object}
     * @property {String} competitorDates - получение доступных дат конкурентов
     * @property {String} competitorList - список конкурентов для выбранного типа
     * @property {String} competitorUserListAdd - добавление конкурента в список пользователя
     * @property {String} competitorUserListRemove - удаление конкурента из списка пользователя
     * @property {String} competitorChangeColor - изменние цвета
     * @property {String} competitorMarkers - получение маркеров
     */
    static _requestRouts = {
        competitorDates: '/competitor/dates',
        competitorList: '/competitor/list',
        competitorUserList: '/competitor/user-list',
        competitorUserListAdd: '/competitor/user-list-add',
        competitorUserListRemove: '/competitor/user-list-remove',
        competitorChangeColor: '/competitor/set-color',
        competitorMarkers: '/competitor/markers'
    };

    /**
     * Подготовить тело запроса для доступных дат
     * @private
     * @static
     * @returns {Object} - тело запроса
     */
    static _getCompetitorDatesBefore() {
        const {city_id} = ServiceFilter.getFilterSelected();
        return {city_ids: city_id};
    }

    /**
     * Подготовить тело запроса для общего списка доступных конкурентов
     * @private
     * @static
     * @param {String} type - тип конкурента
     * @param {Array<Object>} dates - выбранные даты
     * @returns {Object} - тело запроса
     */
    static _getCompetitorListBefore(type, dates) {
        const {city_id} = ServiceFilter.getFilterSelected();
        let stringDates = [];
        dates.forEach((date) => {
            stringDates.push(UtilDate.fromDatepickerDate(date))
        });
        return {
            competitor: {
                city_ids: city_id,
                dates: stringDates,
                type
            }
        };
    }

    /**
     * Подготовить тело запроса для маркеров
     * @private
     * @static
     * @param {Object} competitorObj
     * @param {Array<Object>} dates - выбранные даты
     * @returns {Object} - тело запроса
     */
    static _getCompetitorMarkersBefore(competitorObj, dates) {
        const {city_id} = ServiceFilter.getFilterSelected();
        const [type, esparId] = competitorObj.id.split("-");
        let stringDates = [];
        dates.forEach((date) => {
            stringDates.push(UtilDate.fromDatepickerDate(date))
        });
        return {
            competitor: {
                city_ids: city_id,
                dates: stringDates,
                type,
                espar_ids: [esparId]
            }
        };
    }

    /**
     * Получить доступные даты
     * @public
     * @static
     * @async
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postCompetitorDates(actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.competitorDates,
                this._getCompetitorDatesBefore()
            );
            const {data: datesData = {}} = res;
            const datesDataAfter = this._postCompetitorDatesAfter(datesData);
            actionAfter(datesDataAfter);
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Получить список конкурентов
     * @public
     * @static
     * @async
     * @param {String} type - тип конкурента
     * @param {Array} dates - выбранные даты
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postCompetitorList(type, dates, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.competitorList,
                this._getCompetitorListBefore(type, dates)
            );
            const {data: competitors = {}} = res;
            const {result: competitorsResult = {}} = competitors;
            const competitorsAfter = this._postCompetitorListAfter(type, competitorsResult);
            actionAfter(competitorsAfter);
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Получить список конкурентов пользователя
     * @public
     * @static
     * @async
     * @param {String} type - тип конкурента
     * @param {Array} dates
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postCompetitorUserList(type, dates, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.competitorUserList,
                this._getCompetitorListBefore(type, dates)
            );
            const {data: competitors = {}} = res;
            const {result: competitorsResult = {}} = competitors;
            // console.log(competitorsResult);
            const competitorsAfter = this._postCompetitorUserListAfter(type, competitorsResult);
            actionAfter(competitorsAfter);
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Добавление в список пользователя
     * @public
     * @static
     * @async
     * @param {String} type - тип конкурента
     * @param {Number} competitorId
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postCompetitorUserListAdd(type, competitorId, actionAfter = () => {}) {
        try {
            await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.competitorUserListAdd,
                this._getCompetitorUserListAddBefore(type, competitorId)
            );
            actionAfter();
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Удаление из список пользователя
     * @public
     * @static
     * @async
     * @param {String} type - тип конкурента
     * @param {Number} competitorId
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postCompetitorUserListRemove(type, competitorId, actionAfter = () => {}) {
        try {
            await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.competitorUserListRemove,
                this._getCompetitorUserListAddBefore(type, competitorId)
            );
            actionAfter();
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Изменение цвета конкурента
     * @public
     * @static
     * @async
     * @param {String} type - тип конкурента
     * @param {Number} competitorId
     * @param {String} color
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postCompetitorChangeColor(type, competitorId, color, actionAfter = () => {}) {
        try {
            await ServiceApi.put(
                this._microserviceName,
                this._requestRouts.competitorChangeColor,
                this._getCompetitorChangeColorBefore(type, competitorId, color)
            );
            actionAfter();
        } catch(error) {
            console.log(error);
        }
    }

    /**
     *
     * @param {String} type - тип конкурента
     * @param {Number} competitorId
     * @param {String} color
     * @private
     */
    static _getCompetitorChangeColorBefore(type, competitorId, color){
        return {
            competitor: {
                competitor_id: competitorId,
                type,
                color
            }
        };
    }

    /**
     *
     * @param {String} type - тип конкурента
     * @param {Number} competitorId
     * @private
     */
    static _getCompetitorUserListAddBefore(type, competitorId){
        return {
            competitor: {
                competitor_id: competitorId,
                type
            }
        };
    }

    /**
     * Получить список конкурентов
     * @public
     * @static
     * @async
     * @param {Object} competitorObj
     * @param {Array} dates - выбранные даты
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postCompetitorMarkers(competitorObj, dates, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.competitorMarkers,
                this._getCompetitorMarkersBefore(competitorObj, dates)
            );
            const {data: competitorMarkers = {}} = res;
            const {result: markersResult = {}} = competitorMarkers;
            const competitorsAfter = this._postCompetitorMarkersAfter(competitorObj, markersResult);
            actionAfter(competitorsAfter);
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Адаптер для фронта
     * @param {Object} competitorObj
     * @param {Array<Object>} markersResult
     * @return {Array<Object>}
     * @private
     */
    static _postCompetitorMarkersAfter(competitorObj, markersResult) {
        let markers = [];
        Object.values(markersResult).forEach((markerResult) => {
            let sidesColors = {};
            markerResult.sides.forEach((sideName) => {
                sidesColors[sideName] = competitorObj.color;
            })
            markers.push({
                id: String(markerResult.id),
                additionalId: String(competitorObj?.id),
                lat: String(markerResult.lat),
                lng: String(markerResult.lng),
                direction: String(markerResult.direction),
                fill_color: "#" + competitorObj.color,
                figure: "circle",
                sidesColors,
                selected: false,
                extraData: {
                    groupId: competitorObj.id,
                    type: 'competitor'
                }
            });
        });
        return markers;
    }

    /**
     * Адаптер для фронта
     * @param {Array<String>} datesData - даты с бэка
     * @return {Array<Object>}
     * @private
     */
    static _postCompetitorDatesAfter(datesData) {
        let preparedDates = [];
        let nearest = UtilDate.getNearDate(datesData, false);
        datesData.forEach((date) => {
            preparedDates.push(UtilDate.toDatepickerDate(date))
        })
        UtilDate.getNearDate()
        return {preparedDates, nearest : UtilDate.toDatepickerDate(nearest)};
    }

    /**
     * Адаптер для фронта
     * @param {String} type - тип конкурента
     * @param {Object} competitors - объект конкурентов ключ (id), значение - (name)
     * @return {Array<Object>}
     * @private
     */
    static _postCompetitorListAfter(type, competitors) {
        let preparedList = [];
        Object.keys(competitors).forEach((competitorId) => {
            preparedList.push({
                id: competitorId,
                value: competitors[competitorId]
            });
        })
        return preparedList;
    }

    /**
     * Адаптер для фронта
     * @param {String} type - тип конкурента
     * @param {Object} competitors - объект конкурентов ключ (id), значение - (name)
     * @return {Array<Object>}
     * @private
     */
    static _postCompetitorUserListAfter(type, competitors) {
        let preparedList = [];
        competitors.forEach((competitor) => {
            const {id = 0, name = "", color = "", count = "0"} = competitor;
            preparedList.push({
                id: type + "-" + id,
                key: type,
                description: name,
                color,
                count
            });
        })
        return preparedList;
    }
}
