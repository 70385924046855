<template>
    <div class="kit-panel-placement-add">
        <div class="kit-panel-placement-add__title"> Размещения нет :( </div>
        <BaseButton view="simple">
            <a href="https://map.oohdesk.ru/import" target="_blank">
                <div class="kit-panel-placement-add__btn-title"> Загрузить </div>
            </a>
        </BaseButton>
    </div>
</template>

<script>
import BaseButton from '../Base/BaseButton.vue';
export default {
    name: 'KitPanelPlacementAdd',
    components: {BaseButton}
}
</script>

<style lang="scss" scoped>
    .kit-panel-placement-add {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-width: 180px;
        background: #F4F5F5;
        border-radius: 10px;
        margin-right: 10px;

        &__title {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #AAB0B8;
            margin-bottom: 5px;
        }

        &__btn-title {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #4A91F1;
        }
    }
</style>