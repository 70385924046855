import ServiceApi from "../ServiceApi";
import ServiceToken from "./ServiceToken";
import ServiceUser from "./ServiceUser";
import ServiceSelector from "../ServicesSelector/ServiceSelector";

/** Сервис аутентификации */
export default class ServiceAuth {
    static microserviceName = 'apiMap';
    static routeLogin = '/login';
    static routeAuth = '/login-auth';
    static routeLogout = '/logout'
    /**
     * Получить данные пользователя по токену продукта (product_token)
     * @static
     */
    static init() {}

    /**
     * Аутентификация пользователя по UAS токену (auth_token)
     * @static
     * @async
     */
    static async auth() {
        const postData = {auth_token: ServiceToken.authToken};
        try {
            const response = await ServiceApi.post(this.microserviceName, this.routeAuth, postData);
            this.setTokens(response);
            this.setProfile(response);
        }
        catch (error) {
            await this.logout();
            alert('Не удалось пройти аутентификацию');
        }
    }

    /**
     * Аутентификация пользователя по паре логин, пароль
     * @static
     * @async
     * @param {String} login - логин
     * @param {String} password - пароль
     * @param {Function} actionAfter - событие, сработающее после логина
     * @param {Function} actionError - событие, сработающее при возникновении ошибки
     */
    static async login(login = '', password = '', actionAfter = () => {}, actionError = () => {}) {
        const postData = {email: String(login), password: String(password)};
        try {
            const response = await ServiceApi.post(this.microserviceName, this.routeLogin, postData);
            this.setTokens(response);
            this.setProfile(response);
            actionAfter();
        }
        catch (error) {
            actionError();
        }
    }

    /**
     * Разорвать все активные сессии по UAS токену (auth_token)
     * @static
     * @async
     */
    static async logout() {
        try {
            await ServiceApi.post(this.microserviceName, this.routeLogout);
            ServiceToken.removeAuthToken();
            ServiceToken.removeProductToken();
            ServiceUser.removeProfile();
            ServiceSelector.clearSelected();
            await this.toRoute('login');
        }
        catch (error) {
            console.log(error);
        }
    }

    /**
     * Установить токены
     * @static
     * @param {Object} response - ответ от апи
     */
    static setTokens(response = {}) {
        const {data: responseData = {}} = response;
        const {auth_token: authToken = '', product_token: productToken = ''} = responseData;
        ServiceToken.setAuthToken(String(authToken));
        ServiceToken.setProductToken(String(productToken));
    }

    /**
     * Установить данные профиля
     * @static
     * @param {Object} response - ответ от апи
     */
    static setProfile(response = {}) {
        const {data: responseData = {}} = response;
        const {email = '', profile = ''} = responseData;
        const {company = ''} = profile;
        const profileObj = {email: String(email), company: String(company)};
        ServiceUser.setProfile(profileObj);
    }

    /**
     * Перейти к нужному маршруту
     * @static
     * @param {String} routeName - название маршрута
     */
    static async toRoute(routeName = '') {
        const {currentRoute = {}} = window.vueRouter;
        const {name = ''} = currentRoute;
        if (String(name) !== String(routeName))
            await window.vueRouter.push({name: String(routeName)});
    }
}
