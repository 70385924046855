/** Утилита для работы с цифрами */
export default class UtilNumbers {
    /**
     * Привести цифру к формату "Колличество"
     * @public
     * @static
     * @param {Number|String} num - цифра
     * @returns {String}
     */
    static toCount(num = 0) {
        return isNaN(parseInt(num)) ? '' : `${this.toLocaleNum(num)} ст`;
    }

    /**
     * Привести цифру к формату "Цена"
     * @public
     * @static
     * @param {Number|String} num - цифра
     * @returns {String}
     */
    static toPrice(num = 0) {
        return isNaN(parseInt(num)) ? '' : `${this.toLocaleNum(num)} ₽`;
    }

    /**
     * Разбить цифру по разрядам
     * @public
     * @static
     * @param {Number|String} num - цифра
     * @returns {String}
     */
    static toLocaleNum(num = 0) {
        return isNaN(parseInt(num)) ? '' : parseInt(num).toLocaleString('ru');
    }
}