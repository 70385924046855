const SchemeKitTable = {
    kitTableColumnsBase: [
        {
            prop: 'opn_number',
            label: '№ ОПН',
            canSearch: true,
            canSorted: true,
            columnStyles: {
                width: '80px'
            },
        },
        {
            prop: 'city',
            label: 'Город',
            canSearch: true,
            canSorted: true,
            columnStyles: {
                width: '120px',
            }
        },
        {
            prop: 'supp_item_id',
            label: 'GID',
            canSearch: true,
            canSorted: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['gid-cell'],
        },
        {
            prop: 'realAddress',
            label: 'Адрес',
            canSearch: true,
            canSorted: true,
            columnStyles: {
                width: '300px',
            },
            classes: ['address-cell'],
            maxLength: 80,
        },
        {
            prop: 'code',
            label: 'Сторона',
            canSearch: true,
            canSorted: true,
            columnStyles: {
                textAlign: 'center',
                width: '90px'
            },
            classes: ['code-cell']
        },
        {
            prop: 'supp_id',
            label: 'Оператор',
            canSearch: true,
            canSorted: true,
            columnStyles: {
                width: '150px'
            },
            classes: ['supplier-cell']
        },
        {
            prop: 'type_id',
            label: 'Тип',
            canSearch: true,
            canSorted: true,
            columnStyles: {
                width: '120px',
            }
        },
        {
            prop: 'kind_id',
            label: 'Вид',
            canSearch: true,
            canSorted: true,
            columnStyles: {
                width: '120px'
            }
        },
        {
            prop: 'format_id',
            label: 'Формат',
            canSearch: true,
            canSorted: true,
            columnStyles: {
                width: '90px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['format-cell']
        },
        {
            prop: 'lighted',
            label: 'Свет',
            canSearch: true,
            canSorted: true,
            columnStyles: {
                width: '100px',
            }
        },
        {
            prop: 'ots',
            label: 'OTS',
            canSearch: true,
            canSorted: true,
            columnStyles: {
                width: '70px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['ots-cell']
        },
        {
            prop: 'grp',
            label: 'GRP',
            canSearch: true,
            canSorted: true,
            columnStyles: {
                width: '70px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['grp-cell']
        },
    ]
};

export default SchemeKitTable;