/** Сервис по работе с данными пользователя */
export default class ServiceUser {
    static profileKey = 'USER_PROFILE';

    static userMenuItemsScheme = {
        'myProfile': 'https://map.oohdesk.ru/profile',
        'access': 'https://map.oohdesk.ru/profile/access',
        'managerLink': 'https://map.oohdesk.ru/links',
        'import': 'https://map.oohdesk.ru/import',
        'settings': 'https://map.oohdesk.ru/settings'
    }
    
    /**
     * Получить данные пользователя из локального хранилища
     * @static
     */
    static get profile() {
        return JSON.parse(localStorage.getItem(this.profileKey));
    }
    
    /**
     * Установить данные пользователя в локальное хранилище
     * @static
     * @param {Object} profile - данные пользователя
     */
    static setProfile(profile = {}) {
        localStorage.setItem(this.profileKey, JSON.stringify(profile));
    }

    /**
     * Удалить данные пользователя из локального хранилища
     * @static
     */
    static removeProfile() {
        localStorage.removeItem(this.profileKey);
    }

    static goToUserMenuItem(key = '') {
        window.open(this.userMenuItemsScheme[key] ?? '', '_blank');
    }
}