<template>
    <BaseDropdown
        :isFixed="isFixed"
        :isVirtualScroll="isVirtualScroll"
        :spacerHeight="containerHeight + 50"
        :padding="padding"
        :style-list="{right: '-15px'}"
        :positionValue="positionValue"
        class="dropdown-menu"
        v-bind="$attrs"
        @changeVisible="onDropdown"
    >
        <template #preview>
            <slot name="preview">
                <BaseButtonIcon
                    :is-active="localOpen"
                    class="dropdown-menu__button"
                    :tooltip-text="''"
                    icon-name="tabler-icon-dots-vertical"
                    :is-gray="previewIsGray"
                    :form="previewForm"
                    :style="previewStyle"
                />
            </slot>
        </template>

        <template #content>
            <div class="dropdown-menu__content-wrap">
                <div class="select-dropdown__triangle" />

                <div
                    ref="dropdownMenuContainer"
                    class="dropdown-menu__options"
                    @scroll="onScroll"
                >
                    <div ref="spacer">
                        <div
                            v-for="(option, index) of virtualOptions"
                            :key="index"
                            class="dropdown-menu__item"
                            @click="onCheck(option)"
                            v-tooltip="getTooltip(option.value)"
                        >{{ slicedValue(option.value) }}</div>
                    </div>
                </div>
            </div>
        </template>
    </BaseDropdown>
</template>

<script>
import BaseButtonIcon from "@/components/Base/BaseButtonIcon";
import BaseDropdown from "@/components/Base/BaseDropdown";

export default {
    name: "BaseSelect",
    components: {
        BaseButtonIcon,
        BaseDropdown,
    },
    props: {
        /**
         * @property {Array} options - массив опций
         * @property {Array} value - выбранное значение по умолчанию
         */
        options: {
            type: Array,
            default: () => []
        },
        isCloseAfterCheck: {
            type: Boolean,
            default: true
        },
        open: {
            type: Boolean,
            default: false
        },
        isFixed: {
            type: Boolean,
            default: false
        },
        isVirtualScroll: {
            type: Boolean,
            default: false
        },
        virtualScrollConfig: {
            type: Object,
            default: () => ({})
        },
        padding: {
            type: Number,
            default: 10,
        },
        previewIsGray: {
            type: Boolean,
            default: false
        },
        previewForm: {
            type: String,
            default: '',
        },
        previewStyle: {
            type: Object,
            default: () => ({})
        },
        styleList: {
            type: Object,
            default: () => ({})
        },
        maxLengthItem: {
            type: Number,
            default: 20,
        },
        positionValue: {
			type: String,
			default: '20px'
		},
    },
    data() {
        /**
         * @property {Boolean} localOpen - условие отображение опций
         */
        return {
            localOpen: this.open,
            scrollTop: 0,
            spacerHeight: 0
        }
    },
    computed: {
        virtualOptions() {
            return this.isVirtualScroll ? this.options.slice(this.nodeIndexStart, this.nodeIndexEnd + 1) : this.options;
        },
        nodeHeight() {
            const {nodeHeight = 35} = this.virtualScrollConfig;
            return nodeHeight;
        },
        nodeIndexStart() {
            const nodeIndexStart = Math.floor(this.scrollTop / this.nodeHeight) - 5;
            return Math.max(0, nodeIndexStart);
        },
        nodeIndexEnd() {
            const nodeIndexEnd = this.nodeIndexStart + 12;
            return Math.min(nodeIndexEnd, (this.options.length - 1));
        },
        containerHeight() {
            return this.options.length * this.nodeHeight;
        },
        spacerTopStyle() {
            const spacerTopStyle = `${this.nodeIndexStart * this.nodeHeight}px`;
            return spacerTopStyle;
        },
        spacerBottomStyle() {
            const spacerBottomStyle = `${((this.options.length - 1) - this.nodeIndexEnd) * this.nodeHeight}px`;
            return spacerBottomStyle;
        },

    },
    created() {
		this.$parent.$on('$closeDropdown', this.onClose)
	},
    methods: {
        onScroll(event) {
            if (this.isVirtualScroll) {
                const {currentTarget = {}} = event;
                const {scrollTop = 0} = currentTarget;
                this.scrollTop = scrollTop;
            }
        },

        getSpacerHeight() {
            this.spacerHeight = this.containerHeight;
        },

        /**
         * close
         */
        onClose() {
            this.$emit('$closeDropdown')
            this.$emit('close')
        },
        /**
         * @param {Object} option - option по которому был совершен клик
         */
        onCheck(option) {
            this.$emit('check', option)

            if (this.isCloseAfterCheck) {
                this.onClose()
            }
        },
        onDropdown(event) {
            this.localOpen = event;

            this.$emit('onDropdown', this.localOpen);
        },

        getTooltip(value) {
            return value.length > this.maxLengthItem ? value : '';
        },

        slicedValue(value) {
            return value.length > this.maxLengthItem ? value.slice(0, 20) + '...' : value;
        },
    },
}
</script>

<style lang="scss">
    .dropdown-menu .dropdown {
        right: -15px;
    }

    .dropdown-menu .dropdown-menu__button .base-button-icon__icon {
        font-weight: 700;
    }
</style>

<style lang="scss" scoped>
$color-gray-hover: #F5F5F5;
$color-green: #6EC87A;

.dropdown-menu {

    &__content-wrap {
        height: 100%;
        max-height: 350px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    &__options {
        flex-grow: 1;
        overflow: auto;
    }

    &__item {
        display: flex;
        width: 100%;

        border: 1px solid transparent;
        cursor: pointer;
        // border-radius: 400px;
        border-radius: 4px;
        transition: all .3s ease-in;
        // padding: 8px 15px;
        padding: 6px 10px;
        font-size: 12px;
        font-weight: 400;
        color: #000;

        &:hover {
            background-color: $color-gray-hover;
        }
    }
}
</style>
