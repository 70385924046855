<template>
    <div class="mass-edit-modal">
        <base-modal
            v-if="stage === 'init'"
            :title="title"
            :description="description"
            @close="onCloseModal">
            <template v-slot:modalBody>
                <div class="mass-edit-modal__body-wrap mass-edit-modal__body-wrap--large-mt">
                    <div class="mass-edit-modal__select-wrap">
                        <div class="mass-edit-modal__label">
                            Выберите значение для редактирования
                        </div>
                        <BaseSelect
                            id="modal_select"
                            class="mass-edit-modal__select"
                            :canSearch="true"
                            :is-fixed="true"
                            :is-virtual-scroll="true"
                            :options="options"
                            :value="activeOptionId"
                            :defaultTitle="selectDefaultValue"
                            style="width: 100%"
                            @check="checkSelect"
                        />
                    </div>

                    <div v-if="activeOptionId === 0" class="mass-edit-modal__input-wrap">
                        <div class="mass-edit-modal__label">
                            Напишите значение
                        </div>
                        <BaseTextInput
                            class="mass-edit-modal__input-text"
                            id="field_name"
                            maxlength="70"
                            view="primary"
                            :value="inputName"
                            @input="onInputName"
                        />
                    </div>

                    <template v-if="activeOptionId === 1">
                        <div class="mass-edit-modal__label">
                            Выберите значение
                        </div>

                        <div class="mass-edit-modal__flex-wrap">
                            <div class="mass-edit-modal__checkbox-wrap">
                                <BaseCheckboxV3 :size="16" value="one"/>
                                <span class="mass-edit-modal__checkbox-label">Винил</span>
                            </div>
                            <div class="mass-edit-modal__checkbox-wrap">
                                <BaseCheckboxV3 :size="16" value="two" disabled="true"/>
                                <span class="mass-edit-modal__checkbox-label">Бумага</span>
                            </div>
                        </div>
                    </template>

                    <template v-if="activeOptionId === 2">

                    </template>


                    <div class="mass-edit-modal__actions">
                        <base-button
                            class="mass-edit-modal__action-btn"
                            form="square"
                            view="border"
                            @click="onCancel"
                        >Отменить</base-button>
                        <base-button
                            form="square"
                            view="secondary"
                            @click="onApply"
                            :disabled="isDisabled"
                        >{{ applyBtnText }}</base-button>
                    </div>
                </div>
            </template>
        </base-modal>
        <base-modal
            v-if="stage === 'wait'"
            :title="title"
            @close="onCloseModal">
            <template v-slot:modalBody>
                <div class="mass-edit-modal__body-wrap mass-edit-modal__body-wrap--large-mt">
                    <span class="mass-edit-modal__label">Пожалуйста, подождите...</span>
                    <div class="mass-edit-modal__load-wrap">
                        <span class="mass-edit-modal__load-fill"></span>
                    </div>

                    <div class="mass-edit-modal__actions">
                        <base-button
                            class="mass-edit-modal__action-btn"
                            form="square"
                            view="border"
                            @click="onCancel"
                        >Отменить</base-button>
                    </div>
                </div>
            </template>
        </base-modal>
        <base-modal
            v-if="stage === 'complete'"
            :title="title"
            @close="onCloseModal">
            <template v-slot:modalBody>
                <div class="mass-edit-modal__body-wrap mass-edit-modal__body-wrap--large-mt">
                    <div class="mass-edit-modal__label">
                        Новые значения для 6 сторон отправлены на модерацию в справочник.
                            Но Вы уже можете пользоваться новыми значениями.
                    </div>

                    <div class="mass-edit-modal__actions">
                        <base-button
                            class="mass-edit-modal__action-btn"
                            form="square"
                            view="border"
                            @click="onCancel"
                        >Закрыть</base-button>
                    </div>
                </div>
            </template>
        </base-modal>
        <base-modal
            v-if="stage === 'cancel'"
            :title="title"
            @close="onCloseModal">
            <template v-slot:modalBody>
                <div class="mass-edit-modal__body-wrap mass-edit-modal__body-wrap--large-mt">
                    <div class="mass-edit-modal__label">Изменение данных отменено</div>

                    <div class="mass-edit-modal__actions">
                        <button class="mass-edit-modal__action-alt-btn">Повторить</button>
                        <base-button
                            class="mass-edit-modal__action-btn"
                            form="square"
                            view="border"
                            @click="onCancel"
                        >Закрыть</base-button>
                    </div>
                </div>
            </template>
        </base-modal>
    </div>
</template>

<script>
import BaseModal from "@/components/Base/BaseModal";
import BaseButton from "@/components/Base/BaseButton";
import BaseTextInput from "@/components/Base/BaseTextInput";
import BaseSelect from "@/components/Base/BaseSelect";
import BaseCheckboxV3 from "@/components/Base/BaseCheckboxV3";

export default {
    name: "MassEditModal",
    components: {
        BaseModal,
        BaseButton,
        BaseTextInput,
        BaseSelect,
        BaseCheckboxV3,
    },
    props: {
        stage: {
            type: String,
            default: 'init',
            validation: (val) => ['init', 'wait', 'complete', 'cancel'].includes(val)
        },
        textParams: {
            type: Array,
            default: () => [],
        },
    },

    data: () => ({
        activeOptionId: -1,
        inputName: '',
    }),

    computed: {
        options() {
            return [
                {value: 'Адрес', id: 0},
                {value: 'Материал', id: 1},
                {value: 'Освещение', id: 2},
            ];
        },

        title() {
            let item = this.textParams
                .filter((el) => String(el.stage) === String(this.stage))
                .find((el) => el.title)
            return item?.title ?? '';
        },

        description() {
            return 'Выбрано 6 сторон';
        },


        selectDefaultValue() {
            let item = this.textParams
                .filter((el) => String(el.stage) === String(this.stage))
                .filter((el) => String(el.tab) === String(this.activeTab))
                .find((el) => el.selectDefaultValue)
            return item?.selectDefaultValue ?? '';
        },

        applyBtnText() {
            let item = this.textParams
                .filter((el) => String(el.stage) === String(this.stage))
                .filter((el) => String(el.tab) === String(this.activeTab))
                .find((el) => el.applyBtnText);
            return item?.applyBtnText ?? 'Применить';
        },


        isDisabled() {
            if (this.stage === 'init' && this.activeOptionId === 0) {
                return !this.inputName;
            }

            return true;
        },
    },
    methods: {
        /**
         * Клик на кнопку закрыть модальное окно
         */
        onCloseModal() {
            this.$emit('onCloseModal');
        },

        /**
         * Клик на кнопку Применить
         */
        onApply() {
            this.onCloseModal();
        },

        /**
         * Клик на кнопку Отмена
         */
        onCancel() {
            this.onCloseModal();
        },

        checkSelect(option) {
            this.activeOptionId = Number(option.id);
        },

        onInputName(value = '') {
            this.inputName = value;
        },
    },
}
</script>

<style lang="scss">
$color-gray-light: #f2f2f2;
$color-gray: #AFB0B4;

    .mass-edit-modal .mass-edit-modal__select .select-preview {
        background-color: $color-gray-light;
        color: $color-gray;
        justify-content: space-between;

        &:active, &:focus {
            background-color: #fff;
            color: #000;
        }
    }
</style>

<style lang="scss" scoped>
$color-green: #6EC87A;
$color-gray-light: #f2f2f2;
$color-gray: #AFB0B4;
$color-blue: #4A92F6;

    .mass-edit-modal {
        &__body-wrap {
            margin-top: 20px;

            &--large-mt {
                //margin-top: 20px;
            }
        }

        &__label {
            display: flex;
            align-items: center;

            font-size: 12px;

            margin-bottom: 10px;
        }

        &__flex-wrap {
            display: flex;
        }

        &__checkbox-wrap {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-right: 20px;
            }
        }

        &__checkbox-label {
            margin-left: 5px;
        }

        &__input-text {
            margin-top: 5px;
        }

        &__input-wrap {
        }

        &__select-wrap {
            margin-bottom: 20px;
        }

        &__actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 30px;
        }

        &__action-btn {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        &__action-alt-btn {
            cursor: pointer;
            color: $color-blue;

            &:not(:last-child) {
                margin-right: 30px;
            }

            &:hover {
                color: #000;
            }
        }

        &__select {
            margin-top: 5px;
        }


        &__load-wrap {
            background-color: $color-gray-light;
            height: 20px;
            border-radius: 4px;
            margin-top: 5px;
            margin-bottom: 30px;
        }

        &__load-fill {
            display: block;
            width: 0;
            height: inherit;
            background-color: $color-green;
            animation: loading 1.5s ease-in-out infinite;

            @keyframes loading {
                from {
                    width: 0;
                }

                to {
                    width: 100%;
                }
            }
        }
    }
</style>
