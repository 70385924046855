<template>
    <div class="kit-panel-item">
        <div class="kit-panel-item__row">
            <div
                v-if="!isRenameMode" 
                :class="kitItemNameClass"
                class="kit-panel-item__name"
                @dblclick="onToggleMode(true)"
                @mousedown.stop
            >
                <BasePreloaderSkeleton v-if="isLoading" />
                {{kitItemName}}
            </div>
            <BaseTextInput 
                v-else
                v-model="kitItemNameNew"
                ref="baseTextInput"
                @onBlur="onRename"
            />
        </div>
        <div class="kit-panel-item__row">
            <div class="kit-panel-item__count">
                <BasePreloaderSkeleton v-if="isLoading" />
                {{kitItemCount}}
            </div>
            <div class="kit-panel-item__sum">
                <BasePreloaderSkeleton v-if="isLoading" />
                {{kitItemSum}}
            </div>
        </div>
        <div class="kit-panel-item__row">
            <KitPanelActionList 
                :kitActions="kitActions"
                :kitActionsActive="kitItemActionsActive"
                :kitColor="kitItemColor"
                :kitIsActive="kitItemIsActive"
                :kitIsShared="kitItemIsShared"
                :isLoading="isLoading"
                ref="kitPanelActionList"
                @onClickAction="onClickAction" 
            />
        </div>
    </div>
</template>

<script>
/** Компонент набора (размещение, предложение, макет, проект и  т.д.) */
import KitPanelActionList from './KitPanelActionList.vue';
import BasePreloaderSkeleton from "../Base/BasePreloaderSkeleton.vue";
import BaseTextInput from '../Base/BaseTextInput.vue';
import UtilNumbers from '../../utils/UtilNumbers';
export default {
    name: 'KitPanelItem',
    components: {
        KitPanelActionList,
        BasePreloaderSkeleton,
        BaseTextInput
    },
    /**
     * Входные данные компонента
     * @property {Object} kitItem - набор
     * @property {String} kitItem.id - идентификатор набора
     * @property {String} kitItem.name - название набора
     * @property {String|Number} kitItem.count - колличество сторон набора
     * @property {String|Number} kitItem.sum - сумма цен набора
     * @property {String} kitItem.color - цвет набора
     * @property {Array<String>} kitItem.actionsActive - идентификаторы активных действий набора
     * @property {Array<Object>} kitActions - действия для наборов
     * @property {String} kitActions[].id - идентификатор действия для наборов
     * @property {String} kitActions[].icon - иконка действия для наборов
     * @property {String} kitActions[].emitName - название эмита действия для наборов
     * @property {Boolean} isLoading - условие загрузки
     */
    props: {
        kitItem: {
            type: Object,
            default: () => ({})
        },
        kitActions: {
            type: Array,
            default: () => ([])
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        isRenameMode: false,
        kitItemNameNew: '',
        isRenameEmit: true
    }),
    computed: {
        /**
         * Идентификатор набора
         * @returns {String}
         */
        kitItemId() {
            return String(this.getKitItemProp('id') ?? '');
        },

        /**
         * Название набора
         * @returns {String}
         */
        kitItemName() {
            return String(this.getKitItemProp('name') ?? '');
        },

        /**
         * Колличество сторон набора
         * @returns {String}
         */
        kitItemCount() {
            return UtilNumbers.toCount(String(this.getKitItemProp('count') ?? ''));
        },

        /**
         * Сумма цен набора
         * @returns {String}
         */
        kitItemSum() {
            return UtilNumbers.toPrice(String(this.getKitItemProp('sum') ?? ''));
        },

        /**
         * Цвет набора
         * @returns {String}
         */
        kitItemColor() {
            return String(this.getKitItemProp('color') ?? '');
        },

        /**
         * Идентификаторы активных действий набора
         * @returns {Array<String>}
         */
        kitItemActionsActive() {
            return this.getKitItemProp('actionsActive') ?? [];
        },
        
        /**
         * Активен ли набора
         * @return {Boolean}
         */
        kitItemIsActive() {
            return this.kitItemActionsActive.length > 0;
        },

        /**
         * Класс для набора
         * @return {String}
         */
        kitItemNameClass() {
            return this.kitItemIsActive ? '' : 'kit-panel-item__name_disabled';
        },

        kitIsGeneral() {
            return Boolean(this.getKitItemProp('isGeneral') ?? false);
        },

        kitIsRenameMode() {
            return !this.kitIsGeneral;
        },

        kitItemIsShared() {
            return Boolean(this.getKitItemProp('isShared') ?? false);
        }
    },
    watch: {
        kitItemName() {
            this.kitItemNameNew = this.kitItemName;
        }
    },
    mounted() {
        this.kitItemNameNew = this.kitItemName;
    },
    methods: {
        /**
         * Получить свойство объекта набора
         * @param {String} propKey - ключ объекта набора
         * @returns {String|Number|Object}
         */
        getKitItemProp(propKey = '') {
            return this.kitItem[propKey] ?? null;
        },
        
        /**
         * Событие клика по кнопке действия набора
         * @param {String} kitActionEmitName - название эмита действия
         * @param {String} kitActionId - идентификатор действия
         */
        onClickAction(kitActionEmitName = '', kitActionId = '') {
            this.$emit('onClickAction', kitActionEmitName, kitActionId, this.kitItemId);
        },

        onToggleMode(isRenameMode = false) {
            if(this.kitIsRenameMode)
                this.isRenameMode = Boolean(isRenameMode);

            if(this.isRenameMode)
                this.$nextTick(() => this.$refs.baseTextInput.onFocus());
        },

        onRename() {
            this.onToggleMode(false);
            if (this.isRenameEmit && this.kitItemName !== this.kitItemNameNew) {
                this.isRenameEmit = false;
                this.$emit('onRename', this.kitItemNameNew, this.kitItemId);
                this.$nextTick(() => this.isRenameEmit = true);
            }
        },

        closeMenuContext() {
            this.$refs.kitPanelActionList.closeMenuContext();
        }
    }
}
</script>

<style lang="scss" scoped>
    .kit-panel-item {
        // width: 180px;
        // min-width: 180px;
        height: 80px;
        padding: 10px;
        background: $primary-color;
        border-radius: 10px;
        
        &__row:not(:last-child) {
            display: flex;
            margin-bottom: 5px;
        }

        &__name {
            max-width: 160px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            cursor: pointer;
            position: relative;

            &_disabled {
                color: $assistant-color
            }
        }
        
        @mixin sub-data {
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: $assistant-color;
            position: relative;
        }

        &__count {
            margin-right: 10px;
            
            @include sub-data;
        }

        &__sum {
            @include sub-data;
        }
    }
</style>