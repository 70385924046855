<template>
    <div class="layout-tables-kits">
        <LayoutTableKit
            v-for="(kit, index) in kitItems"
            :key="index"
            :kitItemId="getKitProp(kit, 'id')"
            :kitItemName="getKitProp(kit, 'name')"
            :kitItemColor="getKitProp(kit, 'color')"
            ref="layoutTableKit"
            @onCloseTable="onCloseTable"
            @onCheckRowTable="onCheckRowTable"
            @onCheckRowsAllTable="onCheckRowsAllTable" 
        />
    </div>
</template>

<script>
import LayoutTableKit from './LayoutTableKit.vue';
export default {
    name: 'LayoutTablesKits',
    components: {LayoutTableKit},
    props: {
        kitItems: {
            type: Array,
            default: () => ([])
        },
    },
    methods: {
        getKitProp(kit = {}, prop = '') {
            return kit[prop] ?? '';
        },

        toggleTable(kitItemId = '') {
            const index = this.kitItems.findIndex(kitItem => String(kitItem?.id) === String(kitItemId));
            
            this.$refs.layoutTableKit[index].toggleTable();
        },

        onCloseTable(kitItemId = '') {
            this.$emit('onCloseTable', kitItemId);
        },
        
        onCheckRowTable(kitItemId = '', rowId = '', markerId = '') {
            this.$emit('onCheckRowTable', kitItemId, rowId, markerId);
        },

        onCheckRowsAllTable(kitItemId = '', rows = []) {
            this.$emit('onCheckRowsAllTable', kitItemId, rows);
        }
    }
}
</script>