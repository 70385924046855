<template>
    <div>
        <BaseTextInput
            view="primary"
            v-model.trim="searchRequest"
            @input="onInput"
        />
        <div
            class="dropdown"
            v-if="isOpenedDropdown"
        >
            <div
                 v-if="isLoadingDropdown"
                 class="dropdown-preloader"
            >
                <BasePreloaderGifV2 :size="60" />
            </div>
            <div
                v-else-if="options.length"
                class="select-dropdown"
            >
                <div class="select-dropdown__triangle" />
                <!-- HEADER -->
                <div class="select-dropdown__header">
                    <div class="select-dropdown__top">
                        <slot name="header"/>
                    </div>
                </div>

                <!-- SEARCH -->
                <div
                    v-if="canSearch"
                    class="select-dropdown__search"
                >
                    <input
                        v-model="searchValue"
                        type="text"
                        placeholder="Поиск"
                    >
                </div>

                <div
                    v-if="isMultiple"
                    class="multiple-actions"
                >
                    <BaseButton
                        class="multiple-actions__btn"
                        @click="onSelectAll"
                    >Выбрать все</BaseButton>

                    <BaseButton
                        class="multiple-actions__btn"
                        @click="onClear"
                    >Очистить все</BaseButton>
                </div>

                <!-- OPTIONS -->
                <div
                    ref="selectOptionsContainer"
                    class="select-options"
                    @scroll="onScroll"
                >
                    <div ref="spacer" :style="getSpacerStyle()">
                        <div
                            v-for="(option, index) of virtualOptions"
                            :key="index"
                            class="select-options__item"
                            :class="getOptionClasses(option)"
                            :style="getOptionStyle()"
                            :title="isVirtualScroll ? option.value : null"
                            @click="onCheck(option)"
                        >
                            <span>{{option.value}}</span>
                            <span class="select-options__item-count">({{option.count}})</span>
                        </div>
                    </div>
                </div>

                <!-- FOOTER -->
                <div
                    class="select-dropdown__footer"
                >
                    <slot name="footer">
                        <div v-if="isMultiple" class="select-dropdown__btn-container">
                            <BaseButton
                                class="select-dropdown__apply"
                                view="secondary"
                                form="oval"
                                @click="onApply"
                            >Ок</BaseButton>
                        </div>
                    </slot>
                </div>
            </div>
            <div
                v-else
                class="dropdown-preloader"
            >
                {{requestError}}
            </div>
        </div>
    </div>

</template>

<script>
import BaseButton from "../../../Base/BaseButton";
import BasePreloaderGifV2 from "@/components/Base/BasePreloaderGifV2";
import BaseTextInput from "../../../Base/BaseTextInput";
export default {
    name: "PoiSelectMultiple",
    components: {
        BaseButton,
        BasePreloaderGifV2,
        BaseTextInput
    },
    props: {
        /**
         * @property {Array} options - массив опций
         * @property {Array} value - выбранное значение по умолчанию
         */
        options: {
            type: Array,
            default: () => []
        },
        value: {
            type: [String, Number, Array],
            default: null
        },
        defaultTitle: {
            type: [String, Number],
            default: 'Выбрать значение',
        },
        canSearch: {
            type: Boolean,
            default: false
        },
        isCloseAfterCheck: {
            type: Boolean,
            default: true
        },
        open: {
            type: Boolean,
            default: false
        },
        isCustomPreview: {
            type: Boolean,
            default: false
        },
        previewColor: {
            type: String,
            default: ''
        },
        isFixed: {
            type: Boolean,
            default: false
        },
        isVirtualScroll: {
            type: Boolean,
            default: false
        },
        virtualScrollConfig: {
            type: Object,
            default: () => ({})
        },
        isMultiple: {
            type: Boolean,
            default: true
        },
        isFullWidth: {
            type: Boolean,
            default: false,
        },
        styleList: {
            type: Object,
            default: () => ({})
        },
        previewIcon: {
            type: String,
            default: '',
        },
        previewIconComponent: {
            type: String,
            default: ''
        },
        isLoadingDropdown: {
            type: Boolean,
            default: false,
        },
        isOpenedDropdown: {
            type: Boolean,
            default: false,
        },
        requestError: {
            type: String,
            default: ''
        }
    },
    data() {
        /**
         * @property {Array} valueBuffer - буфер для мультивыбора
         */
        return {
            searchValue: '',
            scrollTop: 0,
            nodeCountVisible: 0,
            spacerHeight: 0,
            valueBuffer: [],
            searchRequest: "",
            inputLazyTimerId: null
        }
    },
    computed: {
        /**
         * Вернет title для селекта
         */
        title() {
            const currentOption = this.options.find(i => i.id === this.value) || {}
            return currentOption.value || this.defaultTitle
        },
        /**
         * Массив значений по ключу поиска
         */
        filteredOptions() {
            const filteredOptions = this.options.filter(item => {
                const optionValue = String(item.value).toLowerCase()
                const searchValue = String(this.searchValue).toLowerCase()

                return optionValue.startsWith(searchValue)
            })
            return filteredOptions;
        },
        virtualOptions() {
            return this.isVirtualScroll ? this.filteredOptions.slice(this.nodeIndexStart, this.nodeIndexEnd + 1) : this.filteredOptions;
        },
        nodeHeight() {
            const {nodeHeight = 35} = this.virtualScrollConfig;
            return nodeHeight;
        },
        nodeIndexStart() {
            const nodeIndexStart = Math.floor(this.scrollTop / this.nodeHeight) - 5;
            return Math.max(0, nodeIndexStart);
        },
        nodeIndexEnd() {
            const nodeIndexEnd = this.nodeIndexStart + 12;
            return Math.min(nodeIndexEnd, (this.filteredOptions.length - 1));
        },
        containerHeight() {
            return this.filteredOptions.length * this.nodeHeight;
        },
        spacerTopStyle() {
            const spacerTopStyle = `${this.nodeIndexStart * this.nodeHeight}px`;
            return spacerTopStyle;
        },
        spacerBottomStyle() {
            const spacerBottomStyle = `${((this.filteredOptions.length - 1) - this.nodeIndexEnd) * this.nodeHeight}px`;
            return spacerBottomStyle;
        },
        localOpen() {
            return this.isLoadingDropdown;
        }
    },
    watch: {
        localOpen(isLocalOpen) {
            if (this.isVirtualScroll) {
                if (isLocalOpen)
                    this.$nextTick(() => {
                        this.getNodeCountVisible();
                        this.getSpacerHeight();
                    });
                else
                    this.scrollTop = 0;
            }
        },
        filteredOptions() {
            if (this.localOpen && this.isVirtualScroll) {
                this.$refs.selectOptionsContainer.scrollTop = 0;
                this.scrollTop = 0;
            }
        },
        value(){
            this.valueBuffer = [...this.value];
        }
    },
    created() {
        // Сработает при эмите события cancel в родителе
        this.$parent.$on('$closeSelect', this.onClose)
    },
    methods: {
        onInput() {
            this.$emit('input', this.searchRequest)
            //TODO: при событии ввода вызываю событие лениового ввода, которое либо дропнет предудыщее, либо выполнится
            this.onInputLazy(this.searchRequest);
        },
        onInputLazy(value = '') {
            if (this.inputLazyTimerId !== null) {
                clearTimeout(this.inputLazyTimerId);
                this.inputLazyTimerId = null;
            }
            this.inputLazyTimerId = setTimeout(() => {
                this.$emit('onInputLazy', value);
            }, 1200);
        },
        onScroll(event) {
            if (this.isVirtualScroll) {
                const {currentTarget = {}} = event;
                const {scrollTop = 0} = currentTarget;
                this.scrollTop = scrollTop;
            }
        },
        updateSearchValues(value){
            this.searchValue = value;
        },
        getNodeCountVisible() {
            const {selectOptionsContainer = {}} = this.$refs;
            const bounding = selectOptionsContainer.getBoundingClientRect() || {};
            const{height = 0} = bounding;
            const nodeCountVisible = Math.floor(height / this.nodeHeight);
            this.nodeCountVisible = nodeCountVisible;
        },
        getSpacerHeight() {
            this.spacerHeight = this.containerHeight;
        },
        getOptionStyle() {
            const optionStyle = {
                'white-space': 'nowrap',
                'height': `${this.nodeHeight}px`,
            };
            return this.isVirtualScroll ? optionStyle : {};
        },
        getSpacerStyle() {
            const spacerStyle = {
                'padding-top': this.spacerTopStyle,
                'padding-bottom': this.spacerBottomStyle
            };
            return this.isVirtualScroll ? spacerStyle : {};
        },
        /**
         * @returns {Object} классы для опций
         */
        getOptionClasses(option) {
            if (Array.isArray(this.valueBuffer)) {
                return {
                    'select-options__item_active': this.valueBuffer.includes(option.id)
                }
            }

            return {
                'select-options__item_active': this.valueBuffer === option.id
            }
        },
        /**
         * close
         */
        onClose() {
            this.$emit('$closeDropdown')
            this.$emit('close')
        },
        /**
         * close
         */
        onApply() {
            if(this.isMultiple){
                this.$emit('check', this.valueBuffer)
            }
            this.$emit('$closeDropdown')
            this.$emit('close')
        },
        /**
         * @param {Object} option - option по которому был совершен клик
         */
        onCheck(option) {
            if (!this.isMultiple) {
                this.$emit('check', option);

                if (this.isCloseAfterCheck) {
                    this.onClose();
                }
            }

            if(this.valueBuffer.includes(option.id)){
                this.valueBuffer = this.valueBuffer.filter(item => item !== option.id)
            }else {
                this.valueBuffer.push(option.id)
            }
        },

        onSelectAll() {
            this.valueBuffer = this.filteredOptions.map((el) => el.id);
        },

        onClear() {
            this.valueBuffer = [];
        },
    }
}
</script>

<style lang="scss">
    .select .dropdown {
        padding-bottom: 10px !important;
    }
</style>

<style lang="scss" scoped>
$primary-color: #F0F3F8 !default;
$success-color: #6EC87A !default;
$primary-disabled-color: #DCE1E8 !default;

.multiple-actions{
    display: flex;
    justify-content: space-between;

    &__btn{
        padding: 5px;
        background: none;
        color: #8d8d8d;
    }
}

.select {
    display: inline-block;
    position: relative;
    width: 130px;
    user-select: none;
    outline: none;
    font-size: 13px;

    &--full-width {
        width: 100%;
    }

    &-preview {
        padding: 6px 15px;
        // border: 1px solid transparent;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        transition: .2s;
        text-align: left;
        &__circle {
            border-radius: 100%;
            width: 14px;
            height: 14px;
            background: #6EC87A;
            margin-right: 5px;
            &-icon {
                padding-left: 2px;
            }
        }

        &__icon {
            font-size: 16px;
            color: #000;
            margin-right: 10px;

            &-component {
                width: 16px;
                height: 16px;
                margin-right: 10px;
            }
        }

        &__label {
            flex-grow: 1;

            font-size: 12px;
            font-weight: 400;
            color: #000;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        &__arrow {
            font-size: 8px;

            margin-left: 5px;
        }
    }

    &-dropdown {
        height: 100%;
        max-height: 250px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding: 5px;

        &__search {
            width: 100%;
            cursor: pointer;
            border-radius: inherit;
            transition: .1s;

            input {
                border: none;
                border-bottom: 1px solid $primary-disabled-color;
                background: transparent;
                height: 34px;
                width: 100%;
                display: block;
                outline: none;
                padding-left: 16px;

                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath id='iconmonstr-magnifier-2' d='M10,9.019,7.394,6.434a4.012,4.012,0,0,0,.78-2.38A4.087,4.087,0,0,0,0,4.054,4.092,4.092,0,0,0,6.391,7.4L9.011,10,10,9.019ZM1.2,4.054A2.888,2.888,0,1,1,4.087,6.92,2.88,2.88,0,0,1,1.2,4.054Z' fill='%23b9bbc2'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center left;
                background-size: 10px;
                font-size: 12px;

                &::placeholder {
                    color: $primary-disabled-color !important;
                }
            }
        }

        &__header {
            flex-shrink: 0;
            flex-grow: 0;
        }
        &__top {
            margin-bottom: 4px;
        }
        &__footer {
            flex-shrink: 0;
            flex-grow: 0;
            margin-top: 10px;
        }

        &__btn-container {
            display: flex;
            justify-content: flex-end;
        }
    }

    &-options {
        flex-grow: 1;
        overflow: auto;
        margin-top: 0;
        padding-right: 10px;

        &__item-count{
            color: #8d8d8d;
        }

        &__item {
            border: 1px solid transparent;
            max-width: 100%;
            cursor: pointer;
            // border-radius: 400px;
            border-radius: 4px;
            transition: .1s;
            // padding: 8px 15px;
            padding: 6px 10px;
            display: flex;
            justify-content: space-between;
            &_required {
                color: #F84967;
            }
            &:hover:not(.select-options__item_active) {
                background: $primary-color;
            }

            span {
                margin-right: 5px;
                // white-space: nowrap;
                display: block;
                text-overflow: ellipsis;
                // white-space: nowrap;
                overflow: hidden;
            }

            &_active {
                // border: 1px solid $success-color;
                color: $success-color;
            }
        }
    }
}

.dropdown {
    cursor: initial;
    position: absolute;
    background: #fff;
    white-space: nowrap;
    z-index: 10;
    box-shadow: 0 3px 15px #21242D17;
    // border-radius: 10px;
    max-width: 400px;

    border: 1px solid #F4F5F5;
    border-radius: 4px;

}
    .dropdown-preloader{
        width: 300px;
        height: 60px;
    }
</style>
