const schemeWidgetMap = {
    containerPoi: {
        headerDropdownItems: [
            {
                name: 'Добавить группу',
                emit: 'onAddNewGroup',
            },
        ],

        groupMenuItems: [
            {
                name: 'Удалить группу',
                emit: 'removeGroupPoi',
            },
        ],

        poiMenuItems: [
            // {
            //     name: 'Удалить метку',
            //     emit: 'removePoi',
            // },
        ],

        addGroupModal: {
            textAddPoi: 'Добавить POI',
            textGroupName: 'Название группы POI',
            textGroupQuery: 'Запрос на поиск POI',
            textPlaceholder: 'Пример: Рестораны',
            textSelectColor: 'Выберите цвет группы (необязательное поле)',
            textSelectIcon: 'Или загрузите собственную иконку группы (необязательное поле)',
            textSelectIconFromIconList: 'Выберите иконку группы (необязательное поле)',
            textAddFile: 'Выберите файл',
            textCancel: 'Отменить',
            textApply: 'Добавить',
        },
    },

}

export default schemeWidgetMap;
