<template>
    <div
        :style="kitActionStyleObj"
        v-tooltip="{content: kitActionDesc}"
        class="kit-panel-action-item"
        @click="onClickAction"
        @mousedown.stop
    >
        <div 
            v-if="kitIsShared && kitActionIsHasLabelChecked"
            class="kit-panel-action-item__label-checked" 
        >
            <IconArrowV2 class="kit-panel-action-item__label-checked-icon"/>
        </div>
        <BasePreloaderSkeleton v-if="isLoading"/>
        <span
            v-if="getActionProp('icon') !== null && !kitActionIsMenuContext"
            :style="kitActionIconStyleObj"
            :class="kitActionIcon"
            class="kit-panel-action-item__icon"
        >
        </span>
        <Icon
            v-if="getActionProp('iconComponent') !== null"
            :name="getActionProp('iconComponent')"
            class="kit-panel-action-item__icon-component"
            :class="kitActionIconComponent"
        />
        <!-- <BaseDropdownMenu 
            v-if="kitActionIsMenuContext"
            :options="kitActionMenuContextItems"
            positionValue="11px"
            :class="{'kit-panel-action-item__menu-context': isDropdownMenuContextItem}"
            @check="checkMenuContextItem"
            @onDropdown="onDropdownMenuContextItem"
        >
            <template #preview>
                <Icon
                    v-if="getActionProp('iconComponent') !== null"
                    :name="getActionProp('iconComponent')"
                    class="kit-panel-action-item__icon-component"
                    :class="kitActionIconComponent"
                />
            </template>
        </BaseDropdownMenu> -->
    </div>
</template>

<script>
/** Компонент действия набора */
import BasePreloaderSkeleton from "../Base/BasePreloaderSkeleton.vue";
// import BaseDropdownMenu from '../Base/BaseDropdownMenu';
import Icon from "../Icons/Icon";
import IconArrowV2 from '../Icons/IconArrowV2.vue';
export default {
    name: 'KitPanelActionItem',
    components: {
        BasePreloaderSkeleton,
        // BaseDropdownMenu,
        Icon,
        IconArrowV2
    },
    /**
     * Входные данные компонента
     * @property {Object} kitAction - объект действия
     * @property {String} kitAction.id - идентификатор действия
     * @property {String} kitAction.icon - иконка действия
     * @property {String} kitAction.emitName - название эмита действия
     * @property {Boolean} kitActionIsActive - активно ли действие
     * @property {String} kitColor - цвет набора
     * @property {Boolean} kitIsActive - активен ли набора
     * @property {Boolean} isLoading - условие загрузки
     */
    props: {
        kitAction: {
            type: Object,
            default: () => ({})
        },
        kitActionIsActive: {
            type: Boolean,
            default: false
        },
        kitColor: {
            type: String,
            default: ''
        },
        kitIsActive: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        kitIsShared: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        isDropdownMenuContextItem: false
    }),
    computed: {
        /**
         * Стиль для кнопки действия
         * @returns {Object}
         */
        kitActionStyleObj() {
            return this.kitIsActive && this.kitActionIsActive ? {'background-color': this.kitColor} : {};
        },

        /**
         * Идентификатор действия
         * @returns {String}
         */
        kitActionId() {
            return String(this.getActionProp('id')) ?? '';
        },

        /**
         * Стиль для иконки кнопки действия
         * @returns {Object}
         */
        kitActionIconStyleObj() {
            return this.kitIsActive && !this.kitActionIsActive ? {color: '#000000'} : {};
        },

        /**
         * Название класса для иконки действия
         * @returns {String}
         */
        kitActionIcon() {
            const iconName = String(this.getActionProp('icon')) ?? '';
            const baseClassName = `${iconName} kit-panel-action-item__icon`;
            return this.kitIsActive ? `${baseClassName}_active` : `${baseClassName}_disabled`;
        },

        /**
         * Название класса для иконки действия
         * @returns {String}
         */
        kitActionIconComponent() {
            const baseClassName = `kit-panel-action-item__icon-component`;
            if(this.kitIsActive && this.kitActionIsActive){
                return `${baseClassName}_active`;
            }else if(!this.kitIsActive && !this.kitActionIsActive){
                return `${baseClassName}_disabled`;
            }else{
                return ''
            }
        },

        /**
         * Название эмита действия
         * @returns {String}
         */
        kitActionEmitName() {
            return String(this.getActionProp('emitName') ?? '');
        },

        kitActionIsMenuContext() {
            return Boolean(this.getActionProp('isMenuContext') ?? false);
        },

        kitActionMenuContextItems() {
            return this.getActionProp('menuContextItems') ?? [];
        },

        kitActionDesc() {
            return String(this.getActionProp('desc') ?? '');
        },

        kitActionIsHasLabelChecked() {
            return Boolean(this.getActionProp('isHasLabelChecked') ?? false);
        }
    },
    methods: {
        /**
         * Получить свойство объекта действия
         * @param {String} propKey - ключ свойства
         * @returns {String|Number|Object}
         */
        getActionProp(propKey = '') {
            return this.kitAction[propKey] ?? null;
        },

        /** Событие клика по кнопке действия */
        onClickAction() {
            this.$emit('onClickAction', this.kitActionEmitName, this.kitActionId);
        },

        checkMenuContextItem(item) {
            const {id = '', emitName = ''} = item;
            this.$emit('onClickAction', emitName, id);
        },

        onDropdownMenuContextItem(isDropdown = false) {
            this.isDropdownMenuContextItem = Boolean(isDropdown);
        },

        closeMenuContext() {
            this.$emit('$closeDropdown');
        }
    }
}
</script>

<style lang="scss" scoped>
    .kit-panel-action-item {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        position: relative;
        transition: background-color .6s ease-out;

        &:hover {
            background-color: #e2e2e2;
            transition: background-color .6s ease-out;
        }

        &:not(:last-child) {
            margin-right: 5px;
        }

        &__label-checked {
            display: flex;
            position: absolute;
            top: 2px;
            right: 2px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #6ec87a;

            &-icon {
                width: 8px;
                height: 8px;

                ::v-deep path {
                    fill: white;
                }
            }
        }

        &__icon {
            &_active {
                color: #FFFFFF;
            }

            &_disabled {
                color: $assistant-color;
            }
        }

        &__icon-component{
            width: 12px;
            height: 12px;
            
            &_active {
                filter: invert(100%) sepia(0%) saturate(7475%) hue-rotate(66deg) brightness(108%) contrast(98%);
            }

            &_disabled {
                filter: invert(94%) sepia(2%) saturate(2343%) hue-rotate(192deg) brightness(78%) contrast(84%);
            }
        }

        &__menu-context {
            position: fixed;
            z-index: 1;
        }
    }
</style>
