/**
 * Схема для карты
 * @type {Object}
 * @property {Array<String>} panelItemsVisible - конфиг видимых элементов панели управления
 */
const SchemeMap = {
    panelItemsVisible: {
        google: [
            'selection', 
            'massSelection', 
            'directionAngleView', 
            'directionFlowSide', 
            'pedestrianTraffic', 
            'autoTraffic', 
            'jamsTraffic', 
            'colorMap', 
            'ruler', 
            // 'clustering'
        ],
        yandex: [
            'selection', 
            'massSelection', 
            'directionAngleView', 
            'directionFlowSide', 
            // 'pedestrianTraffic', 
            // 'autoTraffic', 
            'jamsTraffic', 
            'colorMap', 
            'ruler', 
            // 'clustering'
        ]
    }
}

export default SchemeMap;