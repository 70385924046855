<template>
    <a href="https://map.oohdesk.ru/import" target="_blank" style="height: 80px">
        <div class="kit-panel-offer-add">
            <div class="kit-panel-offer-add__title">
                Загрузить <br> предложение
            </div>
        </div>
    </a>
</template>

<script>
export default {
    name: 'KitPanelOfferAdd'
}
</script>

<style lang="scss" scoped>
    .kit-panel-offer-add {
        width: 180px;
        min-width: 180px;
        height: 100%;
        background: #FFFFFF;
        border: 1px dashed #F2F2F2;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        cursor: pointer;

        &__title {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #AAB0B8;
            text-align: center;
            padding: 5px;
        }
    }
</style>