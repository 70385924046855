import ServiceApi from '../ServiceApi';
import ServiceFilter from '../ServicesFilter/ServiceFilter';
import UtilDate from '../../utils/UtilDate';
import UtilNumbers from '../../utils/UtilNumbers';
import SchemeKitTable from '../../schemes/SchemeKitTable';
const {kitTableColumnsBase = []} = SchemeKitTable;

export default class ServiceMapTable {
    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = 'apiMap';

    /**
     * Маршруты для запросов
     * @private
     * @static
     * @type {Object}
     * @property {String} getTable - маршрут для получения таблицы
     */
    static _requestRouts = {
        getTable: '/main/map-table',
    };

    static columnsPricesStylesScheme = {
        columnStyles: {
            width: '120px',
            textAlign: 'right',
            'justify-content': 'end'
        },
        classes: ['date-cell']
    };

    static columnsPricesScheme = [
        {
            prop: 'agency_price',
            label: 'Цена агенства, ₽',
            ...this.columnsPricesStylesScheme
        },
        {
            prop: 'install_price',
            label: 'Цена монтаж, ₽',
            ...this.columnsPricesStylesScheme
        },
        {
            prop: 'print_price',
            label: 'Цена печать, ₽',
            ...this.columnsPricesStylesScheme
        },
        {
            prop: 'additional_print_price',
            label: 'Цена доп. печать, ₽',
            ...this.columnsPricesStylesScheme
        },
        {
            prop: 'price',
            label: 'Цена, ₽',
            ...this.columnsPricesStylesScheme
        },
    ]; 

    static async getMapTable(page = 0, kitItemId = '', tableFilterData = {}, actionAfter = () => {}) {
        const reqBody = this._getMapTableBefore(page, kitItemId, tableFilterData);
        try {
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.getTable, reqBody);
            const {data = {}} = res;
            const {items: rows = [], count: pageCount = 0} = data;
            const dataAfter = this._getMapTableAfter(rows);
            actionAfter(dataAfter.rowsAfter, dataAfter.columnsAfter, parseInt(pageCount));
        } catch (error) {
            console.log(error);
        }
    }

    static _getMapTableBefore(page = 0, kitItemId = '', tableFilterData = {}) {
        const filterSelected = ServiceFilter.getFilterSelected();
        const {filter = {}, sort = {}} = tableFilterData;
        const reqBody = {
            filter_main: {
                main_params: {...filterSelected},
                price_group_id: String(kitItemId)
            },
            filter_table: {
                page: parseInt(page),
                filter: {...filter},
                sort: {...sort}
            }
        };

        return reqBody;
    }

    static _getMapTableAfter(rows = []) {
        const dataAfter = {
            columnsAfter: this._getMapTableAfterColumns(rows),
            rowsAfter: this._getMapTableAfterRows(rows)
        };

        return dataAfter;
    }

    static _getMapTableAfterColumns(rows = []) {
        const datesTotal = rows.reduce((datesTotal, row) => {
            const {prices = []} = row;
            const datesRow = prices.map(price => String(price?.date));
            const datesAdd = datesRow.filter(date => !datesTotal.includes(date));
            datesTotal.push(...datesAdd);
            datesTotal.sort((dateA, dateB) => {
                return Date.parse(dateA) - Date.parse(dateB);
            });
            return datesTotal;
        }, []);

        const columnsPricesAfter = this.columnsPricesScheme.reduce((columnsPrices, columnPrice) => {
            const columnPricesDates = datesTotal.map(date => {
                const columnPriceDate = {
                    ...columnPrice,
                    prop: `${columnPrice?.prop}__${date}`,
                    label: `${UtilDate.getMonthsName(date)}. ${columnPrice?.label}`,
                };

                return columnPriceDate;
            });

            columnsPrices.push(...columnPricesDates);

            return columnsPrices;
        }, []);

        return [...kitTableColumnsBase, ...columnsPricesAfter];
    }

    static _getMapTableAfterRows(rows = []) {
        const rowsAfter = rows.map(row => {
            const {
                id = '', 
                code = '', 
                sideUserInfo: {
                    opn_number = '', 
                    supp_item_id = '', 
                    realAddress = '',
                    ots = '',
                    grp = ''
                } = {}, 
                propertyLinks: {
                    supp_id: {value: supplier = ''} = {}, 
                    type_id: {value: type = ''} = {}, 
                    kind_id: {value: kind = ''} = {}, 
                    format_id: {value: format = ''} = {}, 
                    lighted: {value: light = ''} = {}
                } = {},
                marker: {city: {name: cityName = ''} = {}} = {},
                prices = []
            } = row;

            const pricesKeys = this.columnsPricesScheme.map(column => String(column?.prop));

            const rowPrices = prices.reduce((rowPrices, price) => {
                const {date = ''} = price;
                pricesKeys.forEach(priceKey => {
                    rowPrices = {
                        ...rowPrices,
                        [`${priceKey}__${date}`]: UtilNumbers.toPrice(price[priceKey])
                    };
                });

                return rowPrices;
            }, {});

            const rowAfter = {
                id: String(id),
                opn_number: String(opn_number),
                city: String(cityName),
                supp_item_id: String(supp_item_id),
                realAddress: String(realAddress),
                code: String(code),
                supp_id: String(supplier),
                type_id: String(type),
                kind_id: String(kind),
                format_id: String(format),
                lighted: String(light),
                ots: String(ots),
                grp: String(grp),
                extraData: {...row},
                ...rowPrices
            };

            return rowAfter;
        });

        return rowsAfter;
    }
}