<template>
    <div class="params">
        <h3 class="params__title" @mousedown="$emit('mouseDown', $event)">Характеристики</h3>

        <div class="params__table">
            <div class="params__table-row">
                <div class="params__table-col-name">Тип</div>
                <div class="params__table-col-value">{{params.type}}</div>
            </div>
<!--            <div class="params__table-row">-->
<!--                <div class="params__table-col-name">Вид</div>-->
<!--                <div class="params__table-col-value">{{params.kind}}</div>-->
<!--            </div>-->
            <div class="params__table-row">
                <div class="params__table-col-name">Формат</div>
                <div class="params__table-col-value">{{params.format}}</div>
            </div>
            <div class="params__table-row">
                <div class="params__table-col-name">Материал</div>
                <div class="params__table-col-value">{{params.material}}</div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: "Params",
    props: {
        params: {
            type: Object,
            default: () => {}
        }
    },
}
</script>

<style lang="scss" scoped>
$color-border: #F2F3F5;
$color-border-table: #F2F2F2;
$color-gray-300: #AFB0B4;

.params {
    background: #fff;
    border-radius: 10px;
    border: 1px solid $color-border;

    padding: 15px 20px 20px 20px;

    &__title {
        width: max-content;
        font-size: 12px;
        font-weight: 400;
        color: $color-gray-300;
        cursor: move;
        padding: 5px 0;

        margin-bottom: 10px;
    }

    &__table {
        display: flex;
        flex-direction: column;
    }

    &__table-row {
        display: flex;
        justify-content: space-between;

        font-size: 12px;
        border-top: 1px solid $color-border-table;
        padding: 7px 0;
    }

    &__table-col-value {
        color: $color-gray-300;
        text-align: right;
    }
}
</style>
