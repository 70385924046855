<template>
    <div class="summary-box">
        <SummaryItem
            v-for="(summary, index) in summaries"
            :key="index"
            :summary="summary"
            @onClose="onCloseItem"
        />
    </div>
</template>

<script>
    import SummaryItem from "./SummaryItem";
    import ServiceSummary from "../../services/ServicesSummary/ServiceSummary";
    import {mapState} from "vuex";
    export default {
        name: "Summary",
        components: {
            SummaryItem
        },
        props: {
            kitsToSummary: {
                type: Array,
                default: () => [],
            }
        },
        /**
         *
         * @returns {Array<Object>} summaries - сводные данные
         */
        data: () => ({
            summaries: []
        }),
        computed:{
            /**
             * Проксируем состояния из стора
             * @returns {Object} state - состояния
             * @returns {Object} state.filterSelectedData - выбранные данные фильтра
             * @returns {Array<Number|String>} state.filterSelectedData.city_id - выбранные идентификаторы городов фильтра
             * @returns {Array<String>} state.filterSelectedData.date - выбранные даты фильтра
             * @returns {Array<Object>} state.selectedSides - выбранные стороны
             */
            ...mapState({
                filterSelectedData: state => state.filter.filterSelectedData,
                selectedSides: state => state.selection.selectedSides
            }),
        },
        beforeMount() {
            this.updateSummaries();
        },
        watch: {
            kitsToSummary(){
                this.updateSummaries();
            },
            async filterSelectedData(){
                this.updateSummaries(["group"]);
            },
            async selectedSides(){
                if(this.selectedSides.length){
                    this.updateSummaries(["selected"]);
                }

            }
        },
        methods:{
            onCloseItem(summaryId){
                this.$emit("onCloseSummary", summaryId);
            },
            updateSummaries(updateActions = []){
                let summaries = [];
                this.kitsToSummary.forEach((kitToSummary) => {
                    const {id, type} = kitToSummary;
                    const summaryId = this.summaries.findIndex(summary => summary.id === id);
                    if(summaryId === -1 || updateActions.includes(type)){
                        ServiceSummary.postSummary(kitToSummary, (newSummary) => {
                            summaries.push(newSummary);
                        });
                    }else{
                        summaries.push(this.summaries[summaryId]);
                    }
                });
                this.summaries = summaries;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .summary-box{
        display: inline-flex;
        float: left;
        /*bottom: 0;*/
        /*position: absolute;*/
    }
</style>
