import Vue from 'vue'
import App from './App.vue'

import store from './store'
import router from './router'
import axios from 'axios'
import VTooltip from 'v-tooltip'
import Clipboard from 'v-clipboard'
import vClickOutside from 'v-click-outside'
import LinceCap from '@oohdesk-kit/lince-cap';
import {
    pluginWidgetMenu as WidgetMenu,
    pluginWidgetMapPoi as WidgetMapPoi,
    pluginColorPanel as ColorPanel,
    pluginWidgetMapBaseLegend as WidgetMapBaseLegend
} from '@oohdesk-kit/lince-widgets-map';

import LinceMapWrapper from '@oohdesk-kit/lince-map';
import LinceRealtimeSystem from '@oohdesk-kit/lince-realtime-system';

import "./assets/scss/main.scss";

const options = {
    autoHide: false,
    defaultDelay: 0,
};

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.use(VTooltip, options)
Vue.use(Clipboard)
Vue.use(vClickOutside)
Vue.use(LinceCap);
Vue.use(LinceMapWrapper);
Vue.use(WidgetMenu);
Vue.use(WidgetMapPoi);
Vue.use(WidgetMapBaseLegend);
Vue.use(ColorPanel);
Vue.use(LinceRealtimeSystem);
window.vueRouter = router;
new Vue({
	render: h => h(App),
	store,
	router,
}).$mount('#app')
