<template>
    <div>
        <base-modal
            @close="onCloseModal"
            class="add-poi-modal"
        >
            <template v-slot:modalBody>
                <div
                    @click.self="isOpenedRequestDropdown = false"
                    class="add-poi-modal__body"
                >
                    <div v-if="isLoading" class="add-poi-modal__preloader">
                        <BasePreloaderGifV2 :size="60" />
                    </div>
                    <template v-if="!isLoading">
                        <h3
                            class="add-poi-modal__header">{{ addGroupModal.textAddPoi }}
                        </h3>

                        <div
                            @click.self="isOpenedRequestDropdown = false"
                            class="add-poi-modal__row"
                        >
                            <p class="add-poi-modal__text">{{ addGroupModal.textGroupName }} <span style="color: red">*</span></p>
                            <BaseTextInput
                                view="primary"
                                :placeholder="addGroupModal.textPlaceholder"
                                v-model.trim="groupName"
                            />
                        </div>

                        <div class="add-poi-modal__row">
                            <p class="add-poi-modal__text">{{ addGroupModal.textGroupQuery }} <span style="color: red">*</span></p>
                            <PoiSelectMultiple
                                style="width: 100%"
                                :options="requestItems"
                                :value="selectedRequestItemIds"
                                :isLoadingDropdown="isLoadingRequest"
                                :isOpenedDropdown="isOpenedRequestDropdown"
                                :requestError="requestError"
                                :canSearch="true"
                                @input="onInput"
                                @onInputLazy="onInputLazy"
                                @check="onSelectRequstIds"
                            />
                        </div>

                        <div
                            @click.self="isOpenedRequestDropdown = false"
                            class="add-poi-modal__row"
                        >
                            <p class="add-poi-modal__text">{{ addGroupModal.textSelectColor }}</p>
                            <input type="color" name="color" id="input-color" v-model="color">
                        </div>

                        <div
                            @click.self="isOpenedRequestDropdown = false"
                            v-if="icons.length > 0"
                            class="add-poi-modal__row"
                        >
                            <div class="add-poi-modal__text">{{ addGroupModal.textSelectIconFromIconList }}</div>
                            <div class="add-poi-modal__icons">
                                <div
                                    v-for="(icon, index) in icons"
                                    :key="index"
                                    class="add-poi-modal__icon-wrap"
                                    :class="{'add-poi-modal__icon-wrap--active': String(iconId) === String(icon.iconId)}"
                                    @click="onSelectIcon(icon.iconId)"
                                >
                                    <img width="20" height="20" :src="icon.src" :alt="'Иконка ' + (index+1)">
                                </div>
                            </div>
                        </div>

                        <div
                            @click.self="isOpenedRequestDropdown = false"
                            class="add-poi-modal__row"
                        >
                            <p class="add-poi-modal__text">{{ addGroupModal.textSelectIcon }}</p>
                            <span
                                v-for="(iconUploadError, indexError) in iconUploadErrors"
                                :key="indexError"
                                class="add-poi-modal__error"
                            >
                                    {{iconUploadError}}
                                </span>
                            <BaseButton
                                v-if="isEmptyFile"
                                class="add-poi-modal__attach-file-button"
                                type="button"
                                view="border"
                                role="label"
                            >
                                <input type="file" hidden @change="onAddFile">
                                {{ addGroupModal.textAddFile }}
                            </BaseButton>
                            <div v-else-if="isLoadingIcon" class="add-poi-modal__preloader">
                                <BasePreloaderGifV2 :size="60" />
                            </div>
                            <BaseButton
                                v-else
                                class="add-poi-modal__file-info"
                            >
                                <span class="add-poi-modal__file-info-title">{{ fileData.name }}</span>
                                <BaseIcon
                                    icon="icon-tabler-icon-trash"
                                    class="add-poi-modal__file-info-icon"
                                    @click.native="fileData = null"
                                />
                            </BaseButton>
                        </div>

                        <div class="add-poi-modal__actions">
                            <base-button
                                class="add-poi-modal__cancel"
                                form="square"
                                view="border"
                                @click="onCloseModal"
                            >{{ addGroupModal.textCancel }}</base-button>
                            <base-button
                                form="square"
                                view="secondary"
                                :disabled="isDisabled"
                                @click="onApply"
                            >{{ addGroupModal.textApply }}</base-button>
                        </div>
                    </template>
                </div>
            </template>
        </base-modal>
    </div>
</template>

<script>
import BaseModal from "@/components/Base/BaseModal.vue";
import BaseButton from "@/components/Base/BaseButton";
import BaseTextInput from "@/components/Base/BaseTextInput";
import BaseIcon from "@/components/Base/BaseIcon";
import schemeWidgetMap from "@/components/MapV2/schemes/SchemeWidgetMap";
import BasePreloaderGifV2 from "@/components/Base/BasePreloaderGifV2";
import PoiSelectMultiple from "../MapV2/components/ContainerPoi/PoiSelectMultiple";

export default {
    name: "AddPoiModal",
    components: {
        BasePreloaderGifV2,
        BaseModal,
        BaseButton,
        BaseTextInput,
        BaseIcon,
        PoiSelectMultiple
    },
    /**
     * Входные Данные компонента
     * @property {Boolean} isLoading - Флаг предыдущее добавление группы еще выполняется
     * @property {Array} icons - Список пользовательских иконок
     * @property {String} uploadIconId - Список пользовательских иконок
     * @property {Boolean} isLoadingIcon
     * @property {Array} iconUploadErrors
     */
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        icons: {
            type: Array,
            default: () => [],
        },
        requestItems: {
            type: Array,
            default: () => [],
        },
        uploadIconId: {
            type: String,
            default: '',
        },
        iconUploadError: {
            type: String,
            default: ''
        },
        isLoadingIcon: {
            type: Boolean,
            default: false,
        },
        isLoadingRequest: {
            type: Boolean,
            default: false,
        },
        requestId:{
            type: Number,
            default: null,
        },
        iconUploadErrors: {
            type: Array,
            default: () => [],
        },
        requestError: {
            type: String,
            default: "",
        }
    },
    /**
     * Данные компонента
     * @property {String} groupName - Список выбранных групп для добавления
     * @property {File} fileData - Список выбранных групп для добавления
     * @property {String} color - Список выбранных групп для добавления
     */
    data: () => ({
        groupName: '',
        fileData: null,
        color: "#000000",
        iconId: null,
        requestQuery: '',
        selectedRequestItemIds: [],
        isOpenedRequestDropdown: false
    }),

    watch: {
        uploadIconId(newVal) {
            if (newVal) {
                this.iconId = newVal;
            }
        },
    },

    computed: {
        /**
         * Подключить scheme
         */
        addGroupModal() {
            const { containerPoi = {} } = schemeWidgetMap;
            const { addGroupModal = {} } = containerPoi;
            return addGroupModal;
        },

        /**
         * Флаг показать/скрыть кнопку загрузки файла
         */
        isEmptyFile() {
            return this.fileData === null;
        },

        /**
         * Вкл/выкл состояние Disabled для кнопки Применить
         */
        isDisabled() {
            return this.groupName.length === 0
                || this.isLoadingIcon
                || (this.requestId === null || this.requestId === 0)
                || !this.selectedRequestItemIds.length;
        },
    },

    methods: {
        onInput(value){
            this.selectedRequestItemIds = [];
            if(this.requestQuery === this.groupName){
                this.groupName = value;
            }
            this.requestQuery = value;
        },
        onInputLazy(value){
            this.isOpenedRequestDropdown = true;
            this.$emit('onAutoComplete', value);
        },
        onSelectRequstIds(values){
            this.isOpenedRequestDropdown = false;
            this.selectedRequestItemIds = values;
        },
        /**
         * Клик на кнопку закрыть модальное окно
         */
        onCloseModal() {
            this.$emit('onCloseModal');
        },

        /**
         * Добавили файл иконки poi
         */
        onAddFile(evt) {
            this.fileData = evt?.target?.files[0] ?? null;
            this.iconId = null;

            this.$emit('onUploadIcon', this.fileData);
        },

        /**
         * Клик на кнопку Применить
         */
        onApply() {
            const requestCreateGroupParams = {
                name: this.groupName,
                request_id: this.requestId,
                request_item_ids: this.selectedRequestItemIds,
                icon_id: null,
                color: this.color
            }
            if (this.iconId) {
                requestCreateGroupParams.icon_id = this.iconId;
            }

            this.$emit('onCreateNewGroup', requestCreateGroupParams);
            // this.onCloseModal();
        },

        /**
         * Выбор иконки из списка
         * @property {String} iconId - id иконки
         */
        onSelectIcon(iconId) {
            this.iconId = Number(iconId);
        },

        /**
         * Загрузить иконку группы в список иконок
         * @property {String} iconId - id иконки
         */
        onUploadIcon(iconId) {
            this.$emit('onUploadIcon', iconId);
        },
    },
}
</script>

<style lang="scss" scoped>
    .add-poi-modal {
        @media (max-width: 576px) {
            width: 300px;
        }

        &__body {
            display: flex;
            flex-direction: column;
            /*width: 360px;*/
            min-height: max-content;
            background: #fff;
            border-radius: 10px;
            box-sizing: border-box;
        }

        &__preloader {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
        }

        &__header {
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: 500;
            color: #000;

            @media (max-width: 576px) {
                font-size: 14px;
            }
        }

        &__row {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 25px;
            }
        }

        &__text {
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 5px;
        }

        &__error {
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 5px;
            color: #ff7c8e;
        }

        &__attach-file-button {
            display: inline-block;
            //border-radius: 1000px;
        }

        &__icons {
            display: flex;
            gap: 5px;
        }

        &__icon-wrap {
            width: 22px;
            height: 22px;
            background: none;
            border-radius: 2px;
            border: 1px solid #e4e8f0;
            cursor: pointer;

            &--active {
                border: 1px solid $active-color;
            }
        }

        &__file-info {
            //max-width: 100%;
            //width: 100%;
            display: flex;
            text-align: left;

            &-title {
                flex-grow: 1;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &-icon {
                font-size: 12px;
                flex-shrink: 0;
                margin-left: 10px;
            }
        }

        &__actions {
            display: flex;
            justify-content: flex-end;

            button {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &__cancel {
            color: #C8CAD1;

            &:hover, &:active {
                color: #333;
            }
        }
    }
</style>
