<template>
    <div class="kit-panel-action-list">
        <KitPanelActionItem
            v-for="(action, index) in kitActionsRender"
            :key="index" 
            :kitAction="action"
            :kitActionIsActive="getKitActionIsActive(action.id)"
            :kitColor="kitColor"
            :kitIsActive="kitIsActive"
            :isLoading="isLoading"
            :kitIsShared="kitIsShared"
            ref="kitPanelActionItems"
            @onClickAction="onClickAction"
        />
    </div>
</template>

<script>
/** Компонент списка действий набора */
import KitPanelActionItem from './KitPanelActionItem.vue';
export default {
    name: 'KitPanelActions',
    components: {KitPanelActionItem},
    /**
     * Входные данные компонента
     * @property {Array<Object>} kitActions - действия для наборов
     * @property {String} kitActions[].id - идентификатор действия для наборов
     * @property {String} kitActions[].icon - иконка действия для наборов
     * @property {String} kitActions[].emitName - название эмита действия для наборов
     * @property {Array<String>} kitActionsActive - список идентификаторов активных кнопок
     * @property {String} kitColor - цвет набора
     * @property {Boolean} kitIsActive - активен ли набора
     * @property {Boolean} isLoading - условие загрузки
     */
    props: {
        kitActions: {
            type: Array,
            default: () => ([])
        },
        kitActionsActive: {
            type: Array,
            default: () => ([])
        },
        kitColor: {
            type: String,
            default: ''
        },
        kitIsActive: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        kitIsShared: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        kitActionsRender() {
            const kitActionMenuContext = this.kitActions.find(kitAction => Boolean(kitAction?.isMenuContext)) ?? {};
            const {id = '', menuContextItems = []} = kitActionMenuContext;
            const kitActionsFirst = this.kitActions.filter(kitAction => {
                const {isMenuContext = false} = kitAction;
                return !isMenuContext;
            });
            return this.kitActionsActive.includes(String(id)) ? [...kitActionsFirst, ...menuContextItems, kitActionMenuContext] : this.kitActions;
        }
    },
    methods: {
        /**
         * Получить флаг активности действия
         * @param {String} actionId - идентификатор действия
         * @returns {Boolean}
         */
        getKitActionIsActive(actionId = '') {
            return this.kitActionsActive.includes(String(actionId));
        },
        
        /**
         * Событие клика по кнопке действия набора
         * @param {String} kitActionEmitName - название эмита действия
         * @param {String} kitActionId - идентификатор действия
         */
        onClickAction(kitActionEmitName = '', kitActionId = '') {
            this.$emit('onClickAction', kitActionEmitName, kitActionId);
        },

        closeMenuContext() {
            this.$refs.kitPanelActionItems.forEach(kitPanelActionItem => {
                kitPanelActionItem.closeMenuContext();
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .kit-panel-action-list {
        display: flex;
    }
</style>