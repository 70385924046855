<template>
    <base-modal @close="onCloseModal">
        <template v-slot:modalBody>
            <div class="modal-share__body">
                <div class="modal-share__title">Поделиться ссылкой</div>
                <div
                    v-tooltip="{
                        content: 'Ссылка скопированная в буфер обмена',
                        show: isTooltip,
                        trigger: 'manual',
                    }"
                    @click="onClipboard"
                >
                    <base-text-input
                        :value="shareLink"
                        view="primary"
                        :isReadOnly="true"
                    />
                </div>
                <div
                    v-if="activeUntilLabel"
                    class="modal-share__activity-container"
                >
                    <div class="modal-share__activity-title">
                        Количество дней жизни ссылки
                    </div>
                    <div class="input-number-custum">
                        <div
                            :disabled="Number(activeUntilDays) <= 0"
                            class="input-number-custum-button input-number-button-minus"
                            @click="onDownDays"
                        >
                            <IconMinus class="input-number-custum-button__icon"/>
                        </div>
                        <input
                            type="number"
                            v-model="activeUntilDays"
                            min="1"
                            max="30"
                            style="appearance: textfield;"
                            @input="onInput"
                        >
                        <div
                            class="input-number-custum-button input-number-button-plus"
                            @click="onUpDays"
                        >
                            <IconPlus class="input-number-custum-button__icon"/>
                        </div>
                        <div class="modal-share__activity-label">
                            {{activeUntilLabel}}
                        </div>
                    </div>
                </div>
                <div
                    v-if="poiItems.length"
                    class="modal-share__activity-container"
                >
                    <div class="modal-share__activity-title">
                        Расшаренные POI
                    </div>
                    <div class="modal-share__activity-tags">
                        <div
                            class="modal-share__activity-tag"
                            v-for="(poiItem, poiIndex) in poiItems"
                            :key="poiIndex"
                        >
                            <img v-if="poiItem.icon" width="20" :src="poiItem.icon" style="margin-right: 5px">
                            <span
                                v-if="!poiItem.icon"
                                :style="{background: '#' + poiItem.color}"
                                style="width: 15px; height:15px; margin-right: 5px"
                            ></span>
                            <span class="modal-share__activity-tag-name">{{poiItem.name}} ({{poiItem.count}})</span>
                        </div>
                    </div>
                </div>

                <div
                    v-if="competitorItems.length"
                    class="modal-share__activity-container"
                >
                    <div class="modal-share__activity-title">
                        Расшаренные Конкуренты
                    </div>
                    <div class="modal-share__activity-tags">
                        <div
                            class="modal-share__activity-tag"
                            v-for="(competitorItem, competitorIndex) in competitorItems"
                            :key="competitorIndex"
                        >
                            <span
                                :style="{background: '#' + competitorItem.color}"
                                style="width: 15px; height:15px; margin-right: 5px"
                            ></span>
                            <span class="modal-share__activity-tag-name">{{competitorItem.name}} ({{competitorItem.count}})</span>
                        </div>
                    </div>
                </div>

                <div class="modal-share__button-container">
                    <base-button
                        :disabled="!shareLink"
                        :style="{marginLeft: '10px'}"
                        @click="onRemove"
                    >
                        Удалить
                    </base-button>
                    <base-button
                        :style="{marginLeft: '10px'}"
                        view="secondary"
                        @click="onClipboard"
                    >
                        Копировать
                    </base-button>
                </div>
            </div>
        </template>
    </base-modal>
</template>

<script>
/** Компонент модального окна для отображения ссылки для шары */
import BaseModal from '@/components/Base/BaseModal';
import BaseTextInput from '@/components/Base/BaseTextInput';
import BaseButton from "@/components/Base/BaseButton";
import IconPlus from '../../components/Icons/IconPlus.vue';
import IconMinus from '../../components/Icons/IconMinus.vue';
import ServiceShare from "../../services/ServicesAuth/ServiceShare";
import UtilDate from '../../utils/UtilDate';
export default {
    name: 'ModalShare',
    components: {
        BaseModal,
        BaseTextInput,
        BaseButton,
        IconPlus,
        IconMinus
    },
    /**
     * Входные данные компонента
     * @property {String} shareLink - ссылка
     */
    props: {
        sharedKit: {
            type: Number,
            default: null
        },
        sharedParams: {
            type: Object,
            default: () => {}
        }
    },
    /**
     * @property {Boolean} isTooltip - флаг отображение тултипа
     * @property {String} shareLink - сама ссылка
     * @property {String} activeUntilLabel - дата окончания действия ссылки
     * @property {Number} activeUntilDays - кол-во дней
     * @property {Number} inputLazyTimerId - таймер ленивого редактирования ссылки
     */
    data: () => ({
        /**
         * Условие рендера тултипа
         * @type {Boolean}
         */
        isTooltip: false,
        shareLink: '',
        activeUntilLabel: "",
        activeUntilDays: 0,
        inputLazyTimerId: null,
        poiItems: [],
        competitorItemsDates: [],
        competitorItems: []
    }),
    beforeMount() {
        ServiceShare.getLink(this.sharedKit, this.sharedParams, (linkObj) => {
            const {link = "", activeUntilLabel = "", daysCount = 0, poiItems = [], competitorItems = {}} = linkObj;
            const {dates = [], list = []} = competitorItems;
            this.activeUntilDays = daysCount;
            this.activeUntilLabel = activeUntilLabel;
            this.shareLink = link;
            this.poiItems = poiItems;
            this.competitorItemsDates = dates.map(date => UtilDate.toDatepickerDate(date));
            this.competitorItems = list;
        });
    },
    methods: {
        onDownDays(){
            this.activeUntilDays = this.activeUntilDays <= 0 ? 0 : (this.activeUntilDays - 1)
            this.onInput();
        },
        onUpDays(){
            this.activeUntilDays = this.activeUntilDays >= 30 ? 30 : (this.activeUntilDays + 1);
            this.onInput();
        },
        onInput() {
            if(this.activeUntilDays > 30){
                this.activeUntilDays = 30
            }else if(this.activeUntilDays < 0){
                this.activeUntilDays = 0
            }
            if (this.inputLazyTimerId !== null) {
                clearTimeout(this.inputLazyTimerId);
                this.inputLazyTimerId = null;
            }
            this.inputLazyTimerId = setTimeout(() => {
                ServiceShare.editLink(this.sharedKit, this.activeUntilDays, (linkObj) => {
                    const {link = "", activeUntilLabel = "", daysCount = 0} = linkObj;
                    this.activeUntilDays = daysCount;
                    this.activeUntilLabel = activeUntilLabel;
                    this.shareLink = link;
                });
            }, 500);
        },
        onRemove(){
            ServiceShare.removeLink(this.sharedKit, () => {
                this.$emit("removeKitLink")
            });
        },
        /**
         * Событие копирования ссылки в буфер обмена
         */
        onClipboard() {
            this.isTooltip = true;
            setTimeout(() => this.isTooltip = false, 1000);
            this.$clipboard(this.shareLink);
        },
        /**
         * Событие закрытия модального окна
         */
        onCloseModal() {
            this.$emit('onCloseModal');
        }
    }
}
</script>

<style lang="scss" scoped>
    .modal-share {
        &__title {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px;
        }
        &__activity-container {
            padding-top: 15px;
        }
        &__activity-title{
            font-size: 12px;
            font-weight: 500;
            color: #ADB0BB;
            margin-bottom: 5px;
        }

        &__activity-tags {
            display: flex;
            flex-wrap: wrap;
        }

        &__activity-tag{
            // display: flex;
            // align-items: center;
            margin-right: 5px;
            // margin-bottom: 5px;
            display: flex;
            padding: 6px 10px;
            background: #F5F5F5;
            border-radius: 50px;
            margin-bottom: 4px;
        }

        &__activity-tag-name {
            display: flex;
            align-items: center;
        }

        &__activity-label{
            display: flex;
            align-items: center;
            padding-left: 10px;
            font-size: 12px;
            color: #ADB0BB;
        }

        &__button-container {
            width: 100%;
            display: flex;
            justify-content: right;
            margin-top: 30px;
        }
        &__button-cancel {
            color: #C8CAD1;

            &:hover {
                color: #000;
            }
        }
    }

    .input-number-custum {
        display: flex;
        justify-content: left;
        margin-top: 8px;
    }

    .input-number-button-minus {
        border-right: 0;
        border-radius: 4px 0 0 4px;
    }

    .input-number-custum input[type="number"] {
        width: 36px;
        height: 36px;
        border: 1px solid #dcdfe5;
        border-radius: 10px;
        display: inline-block;
        text-align: center;
        margin-right: 4px;

        &:active, &:hover, &:focus {
            outline: 0;
            outline-offset: 0;
        }

        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .input-number-button-plus {
        border-left: 0;
        border-radius: 0 4px 4px 0;
    }

    .input-number-custum-button {
        background: #fff;
        font-size: 14px;
        font-weight: bold;
        // width: 24px;
        // height: 34px;
        text-align: center;
        line-height: 34px;
        // display: inline-block;
        border: 1px solid #dcdfe5;
        border-left-color: rgb(220, 223, 229);
        border-left-style: solid;
        border-left-width: 1px;
        cursor: pointer;
        outline: 0;
        -webkit-transition: all 250ms;
        -moz-transition: all 250ms;
        transition: all 250ms;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 10px;
        padding: 0;
        margin-right: 4px;

        &__icon {
            width: 18px;
            height: 18px;
        }
    }
</style>
