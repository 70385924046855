import UtilNumbers from "../utils/UtilNumbers";
import UtilDate from "../utils/UtilDate";

/** Менеджер параметров для карточки */
export default class ManagerDraggableCard {

    static _paramsModifier = {
        Budget: (activeSideData) => {
            let {activePrice = {}} = activeSideData;
            let {
                price,
                agency_price: agencyPrice = "",
                install_price: installPrice = "",
                print_price: printPrice = "",
                additional_print_price: additionalPrintPrice = ""
            } = activePrice;
            let budget = Number(agencyPrice) + Number(installPrice) + Number(printPrice);
            let discount = Number(price) ? (1 - Number(agencyPrice)/Number(price)) : 0;
            console.log(printPrice);
            return {
                price: Number(price) ? UtilNumbers.toPrice(price) : "",
                budget: Number(budget) ? UtilNumbers.toPrice(budget) : "",
                agencyPrice: Number(agencyPrice) ? UtilNumbers.toPrice(agencyPrice) : "",
                installPrice: Number(installPrice) ? UtilNumbers.toPrice(installPrice) : "",
                printPrice: Number(printPrice) ? UtilNumbers.toPrice(printPrice) : "",
                additionalPrintPrice: Number(additionalPrintPrice) ? UtilNumbers.toPrice(additionalPrintPrice) : "",
                discount: Number(discount) ? `${Math.round(discount * 100)} %` : ''
            };
        },
        Site: (activeSideData) => {
            let {marker = {}} = activeSideData;
            let {lat = "", lng = ""} = marker;
            return {lat, lng};
        },
        Projects: (activeSideData) => {
            let {activePrice = {}, id = ""} = activeSideData;
            return {priceId: id, projectList: this._getPriceGroupList("4", activePrice)}
        },
        Description: (activeSideData) => {
            let {city = {}, sideUserInfo = {}, propertyLinks = {}, activePrice = {}, updated_at: sideUpdatedAt = ""} = activeSideData;
            let {updated_at: priceUpdatedAt = ""} = activePrice;
            let lastUpdate = Number(sideUpdatedAt) > Number(priceUpdatedAt)
                ? Number(sideUpdatedAt) : Number(priceUpdatedAt);
            let date = new Date(Number(lastUpdate + "000"));

            let {supp_id:supplier = {}, kind_id: kind} = propertyLinks;
            let {value: supplierValue = ''} = supplier;
            let {value: kindValue = ''} = kind;

            let {
                opn_number:opnNumber = '',
                supp_item_id:gid = '',
                grp = '',
                ots = '',
                move_direction: moveDirection = '',
                realAddress = '',
                image_src: imageSrc = ''
            } = sideUserInfo;

            let {name = ''} = city;
            return {
                supplier: supplierValue,
                kind: kindValue,
                updatedAt: UtilDate.toPrettyString(date.getFullYear(), date.getMonth(), date.getDay()),
                moveDirection,
                ots,
                grp,
                gid,
                opnNumber,
                city: name,
                address: realAddress,
                imageSrc
            };
        },
        Params: (activeSideData) => {
            let {propertyLinks = {}, sideUserInfo = {}} = activeSideData;
            let {kind_id:kind = {}, type_id:type = {}, format_id:format = {}} = propertyLinks;
            let {material = ''} = sideUserInfo;
            let {value: typeValue = ''} = type;
            let {value: kindValue = ''} = kind;
            let {value: formatValue = ''} = format;
            return {type: typeValue, kind: kindValue, format: formatValue, material}
        },
        Comment: (activeSideData) => {
            let {activePrice = {}} = activeSideData;
            let {comment = ""} = activePrice;
            return {comment};
        },
        Maket: (activeSideData) => {
            let {activePrice = {}, id = ""} = activeSideData;
            return {priceId: id, layoutList: this._getPriceGroupList("3", activePrice)}
        },
        Photo: (activeSideData) => {
            let {catalogData = {}} = activeSideData;
            let {photo = {}} = catalogData;
            let {middle = ""} = photo;
            return {photoMiddle: middle};
        }
    }

    static getParams(componentName, activeSideData){
        const loader = this._paramsModifier[componentName] ?? function () {};
        let data = loader(activeSideData);
        return data;
    }

    /**
     * Список макетов или проектов
     * @param {String} type
     * @param {Object} activePrice
     * @returns {[]}
     */
    static _getPriceGroupList(type, activePrice){
        let {priceGroups = []} = activePrice;
        let list = [];
        priceGroups.forEach((item) => {
            let {id, name, type: groupType} = item;
            if(groupType === type){
                list.push({id, name});
            }
        });
        return list;
    }

}
