import ServiceApi from "../ServiceApi";
import ServiceFilter from "../ServicesFilter/ServiceFilter";
import ServiceSelector from "../ServicesSelector/ServiceSelector";
/** Сервис для работы с сводными данными */
export default class ServiceSummary {

    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = 'apiMap';

    /**
     * Маршруты для запросов
     * @private
     * @static
     * @type {Object}
     * @property {String} summaryGroup - маршрут для получения легенды группы
     * @property {String} summarySelected - маршрут для получения легенды выбранного
     */
    static _requestRouts = {
        summaryGroup: '/main/map-summary',
        summarySelected: '/main/selection-summary'
    };

    /**
     * Подготовить тело запроса для сводных данных
     * @private
     * @static
     * @param {Object} kitParams - параметры набора
     * @param {String} kitParams.kitItemId - id набора
     * @param {String} kitParams.kitItemColor - цвет набора
     * @returns {Object} reqBody - тело запроса
     * @returns {Object} reqBody.filter_main - тело фильтра
     * @returns {Object} reqBody.filter_main.main_params - выбранные параметры фильтра
     * @returns {Array<Number|String>} reqBody.filter_main.main_params.city_id - выбранные идентификаторы городов фильтра
     * @returns {Array<String>} reqBody.filter_main.main_params.date - выбранные даты фильтра
     * @returns {Object} reqBody.filter_table - тело фильтра таблицы
     * @returns {Object} reqBody.filter_table.page - номер страницы для загрузки
     */
    static _getSummaryKitBefore(kitParams = {}) {
        const {id: kitItemId = ''} = kitParams;
        const filterSelected = ServiceFilter.getFilterSelected();
        const reqBody = {
            filter_main: {
                main_params: {...filterSelected},
                price_group_id: String(kitItemId)
            }
        };

        return reqBody;
    }

    /**
     * Подготовить тело запроса для получения маркеров
     * @private
     * @static
     * @returns {Object} reqBody - тело запроса
     * @returns {Object} reqBody.filter_main - тело фильтра
     * @returns {Object} reqBody.filter_main.main_params - выбранные параметры фильтра
     * @returns {Array<Number|String>} reqBody.filter_main.main_params.city_id - выбранные идентификаторы городов фильтра
     * @returns {Array<String>} reqBody.filter_main.main_params.date - выбранные даты фильтра
     * @returns {Object} reqBody.filter_table - тело фильтра таблицы
     * @returns {Object} reqBody.filter_table.page - номер страницы для загрузки
     */
    static _getSummarySelectedBefore() {
        const priceIds = ServiceSelector.getPriceSelected();
        const reqBody = {
            select: {
                ids: priceIds
            }
        };
        return reqBody;
    }

    /**
     * Получить данные фильтра
     * @public
     * @static
     * @async
     * @param {Object} kitParams - параметры набора
     * @param {String} kitParams.kitItemId - id набора
     * @param {String} kitParams.kitItemName - название набора
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postSummary(kitParams, actionAfter = () => {}) {
        const {type} = kitParams;
        switch (type) {
            case "group":
                this.postSummaryGroup(kitParams, actionAfter)
                break;
            case "selected":
                this.postSummarySelected(kitParams, actionAfter)
                break;
        }
    }

    /**
     * Получить сводные данные группы
     * @public
     * @static
     * @async
     * @param {Object} kitParams - параметры набора
     * @param {String} kitParams.kitItemId - id набора
     * @param {String} kitParams.kitItemName - название набора
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postSummaryGroup(kitParams, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.summaryGroup,
                this._getSummaryKitBefore(kitParams)
            );
            const {data: summaryData = {}} = res;
            const summaryDataAfter = this._postSummaryAfter(kitParams, summaryData);
            actionAfter(summaryDataAfter);
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Получить данные фильтра
     * @public
     * @static
     * @async
     * @param {Object} kitParams - параметры набора
     * @param {String} kitParams.kitItemId - id набора
     * @param {String} kitParams.kitItemName - название набора
     * @param {Function} actionAfter - событие, сработающее после получения фильтра
     */
    static async postSummarySelected(kitParams, actionAfter = () => {}) {
        try {
            const res = await ServiceApi.post(
                this._microserviceName,
                this._requestRouts.summarySelected,
                this._getSummarySelectedBefore()
            );
            const {data: summaryData = {}} = res;
            const summaryDataAfter = this._postSummaryAfter(kitParams, summaryData);
            actionAfter(summaryDataAfter);
        } catch(error) {
            console.log(error);
        }
    }

    /**
     * Адаптер для фронта
     * @param {Object} kitParams - параметры набора
     * @param {String} kitParams.kitItemId - id набора
     * @param {String} kitParams.kitItemName - название набора
     * @param {Object} summaryData - данные с бэка
     * @return {{price: {name: string, value: number}, name: string, count: number, groups: [], budget: {name: string, value: number, sums: [{name: string, value: number}, {name: string, value: number}, {name: string, value: number}]}}}
     * @private
     */
    static _postSummaryAfter(kitParams, summaryData) {
        const {name: kitItemName = '', id: kitItemId} = kitParams;
        const {total = {}, groups = []} = summaryData;
        const {
            budget = 0,
            side_count: sideCount = 0,
            price = 0,
            agency_price: agencyPrice = 0,
            print_price: printPrice = 0,
            install_price: installPrice = 0
        } = total;
        let result = {
            id: kitItemId,
            name: kitItemName,
            count: sideCount,
            budget: {
                name: "Бюджет",
                value: budget,
                sums: [
                    {name: "Размещение", value: agencyPrice},
                    {name: "Печать", value: printPrice},
                    {name: "Монтаж", value: installPrice},
                ]
            },
            price: {name: "Прайс", value: price},
            groups: []
        };
        groups.forEach((group) => {
            const {name = "", items = []} = group;
            let summaryGroup = {
                name,
                sums: []
            }
            items.forEach((item) => {
                const {name: itemName = "", sums = {}} = item;
                const {/*side_count: itemSideCount = 0, */budget: itemSideBudget = 0} = sums;
                summaryGroup.sums.push({
                    name: itemName,
                    // count: itemSideCount,
                    value: itemSideBudget,
                });
            })
            result.groups.push(summaryGroup);
        });
        return result;
    }
}
