<template>
    <div
        id="modal__backdrop"
        class="modal"
        @click.self="close"
    >
        <div
            class="modal__container"
            :class="{'modal__container--animation' : isAnimation}"
        >
            <button class="modal__btn-close icon-tabler-icon-x" @click="close"></button>

            <div v-if="title" class="modal__body-title">{{ title }}</div>
            <div v-if="description" class="modal__body-description">{{ description }}</div>
            <slot name="modalBody"></slot>
            <div class="modal__body-actions-wrap">
                <slot name="actionButtons"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseModal',
    components: {
    },
    /**
     * Входные данные компонента
     */
    props: {
        isAnimation: {
            type: Boolean,
            default: false,
        },
        isCloseButton: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
    },
    /**
     * данные компонента
     */
    data: () => ({
    }),
    methods: {
        /**
         * событие закрытия модельного окна
         */
        close() {
            this.$emit("close");
        }
    }
}
</script>

<style lang="scss" scoped>
$color-gray: #AFB0B4;
    .modal {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: auto;
        z-index: 11;

        &__container {
            background-color: white;
            position: relative;
            width: 460px;
            max-width: 80%;
            /*max-height: 80%;*/
            border-radius: 10px;
            padding: 30px;

            &--animation {
                animation-duration: .2s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: 2;
                animation-name: modalOpenAnimation;
            }
        }

        &__body-title {
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 0;
            color: #000;
            margin-bottom: 5px;
        }

        &__body-description {
            font-size: 12px;
            letter-spacing: 0;
            color: #000;
            margin-bottom: 20px;
        }

        &__btn-close {
            position: absolute;
            top: 16px;
            right: 16px;
            margin: 0;
            padding: 0;
            font-size: 12px;
            color: $color-gray;
            z-index: 12;
            transition: all .4s ease-out;

            &:hover, &:active {
                color: #000;

            }
        }
    };

    @keyframes modalOpenAnimation {
        from {
            transform: translateX(-40px);
        }

        50% {
            transform: translateX(40px);
        }

        to {
            transform: translateY(0);
        }
    }
</style>
