<template>
    <div class="layout">
        <div class="layout__main">
            <Cap
                class="layout__cap"
                :title="title || $route.meta.title || 'Oohdesk'"
                :logo-src="capLogoSrc"
                view="transparent"
                :config="capConfig"
                :status="preparedLastStatus"
                :user-menu-data="profile"
                :userMenuDropdownItems="userMenuDropdownItems"
                :historyItems="historyItems"
                :isShowAppMenu="isMenuOpen"
                @onHamburger="onHamburger"
                @onHistoryDropdownChangeVisible="historyDropdownChangeVisible"
                @onFilter="onFilter"
                @onUserMenuLogout="logout"
                @onUserMenuItem="onUserMenuItem"
            >
                <template v-slot:filterPanel>
                    <FilterPanel
                        :cities="citiesList"
                        :selectedCities="selectedCities"
                        :accessDates="accessDates"
                        :checkedDates="checkedDates"
                        :isFilterOpen="isFilterOpen"
                        :isLoading="isFilterPanelLoading"
                        @toggle="onFilter"
                        @checkCity="onCheckCities"
                        @approveDatepicker="onApproveDatepicker"
                    />
                </template>
            </Cap>
            <div
                class="layout__content"
                :style="layoutContentStyle"
            >
                <AppFilter
                    v-if="isFilterOpen"
                    :params="filterDataParams"
                    :selected="filterDataSelected"
                    :isLoading="isFilterLoading"
                    :panelData="filterPanelData"
                    :sidesNumber="filterSidesNumber"
                    class="layout__filter"
                    @check="onCheckFilter"
                    @transfer="onTransfer"
                    @close="onFilter"
                    @clear="onResetFilter"
                />
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import AppFilter from '@/components/Filter/Filter'
import FilterPanel from './components/FilterPanel'
// import ServiceApi from '../services/ServiceApi';
import ServiceAuth from "@/services/ServicesAuth/ServiceAuth";
import ServiceUser from "@/services/ServicesAuth/ServiceUser";
import ServiceFilter from "@/services/ServicesFilter/ServiceFilter";
import MixinApi from "@/mixins/MixinsPageBayuerdesk/MixinApi";
import MixiHistory from "@/mixins/MixiHistory";
import SchemeUserMenu from "../schemes/SchemeUserMenu";
const {userMenuDropdownItems} = SchemeUserMenu;

export default {
    name: 'LayoutWithFilter',
    components: {
        AppFilter,
        FilterPanel,
    },
    mixins: [
        MixinApi,
        MixiHistory,
    ],

    data() {
        return {
            isMenuOpen: false,
            isFilterOpen: false,
            citiesList: [],
            profile: {},
            lastDate: '',
            title: '',
            filterDataParams: [],
            filterDataSelected: {},
            filterDataAccessDates: [],
            isFilterLoading: false,
            priceGroups: [],
            filterSidesNumber: 0,
            // withoutPricesStepCountData: 0,
            // withoutPricesSideCountData: 0,
            filterPanelData: [
				{ value: 'through', title: 'Сквозное размещение' },
				{ value: 'without_prices', title: 'Стороны без цен' },
			],
            userMenuDropdownItems: [...userMenuDropdownItems]
        }
    },

    async created() {
        await this.fetchFilterData();
        await this.fetchExtendFilter();
        await this.fetchLastUpdate();
    },

    async mounted() {
        const {currentRoute = {}} = this.$router;
        const {name = ''} = currentRoute;
        if (name === 'map') {
            this.profile = ServiceUser.profile || {};
        }
    },

    watch: {
        filterDataSelected: function (filterDataSelected) {
            const {city_id: cityId = [2], date = ['2022-07-01']} = filterDataSelected;
            if (cityId.length ||  date.length) {
                this.setFilterSelectedData(filterDataSelected);
                ServiceFilter.setFilterSelected(filterDataSelected);
            }
        },
    },

    computed: {
        ...mapState({
            currentComponent: state => state.currentComponent,
            isFilterPanelLoading: state => state.filter.isFilterLoading
        }),

        preparedLastStatus(){
            return this.lastDate.length ? ("Обновлено " + this.lastDate) : ""
        },

        isGuest(){
            return this.isShare && !Object.keys(this.profile).length
        },

        isShare() {
			const {query = {}} = this.$route;
			const {shareToken = null} = query;
			return shareToken !== null;
		},

        layoutContentStyle() {
            const layoutContentStyle = this.currentComponent === 'PageMap' ? {'padding': '0px'} : {};
            return layoutContentStyle;
        },

        selectedCities() {
            const cityIds = this.filterDataSelected['city_id'] || []
            const result = cityIds.map(i => String(i));
            return result;
        },

        accessDates() {
            const dates = this.filterDataAccessDates ?? [];
            const result = dates.map((item) => {
                const YY_MM_DD = item.split('-');
                const [year, month] = YY_MM_DD;

                return { year: Number(year), month: Number(month) };
            });

            return result;
        },

        checkedDates() {
            const dates = this.filterDataSelected?.date || []

            const result = dates.map((date) => {
                const YY_MM_DD = date.split('-')
                const [year, month] = YY_MM_DD;

                return { year: Number(year), month: Number(month) };
            })

            return result;
        },

        capConfig() {
            return {
                isConfigHamburger: false,
                isConfigStatus: true,
                isConfigNotification: !this.isGuest,
                isConfigSettings: !this.isGuest,
                isConfigImport: !this.isGuest,
                isConfigUserMenu: !this.isGuest
            };
        },

        capLogoSrc() {
            return require('@/assets/images/logo-new.svg');
        },
    },

    methods: {
        ...mapMutations('filter', [
            'setFilterSelectedData',
            // 'setWithoutPricesStepCount',
            'setIsFilterLoading',
            // 'setWithoutPricesSideCount',
        ]),

        /**
         * Получение последней даты обновления
         * @async
         */
        async fetchLastUpdate() {
            await ServiceFilter.getLastUpdate((result) => {
                const {last_date: lastDate = "", title = ""} = result;
                this.lastDate = lastDate;
                this.title = title;
            });
        },

        /**
         * Получение фильтра
         * @async
         */
        async fetchFilterData() {
            this.setIsFilterLoading(true);
            let {city_id: cityId = null} = this.filterDataSelected;
            await ServiceFilter.postFilterMain(cityId, (filterData) => {
                const {filter = {}, selected = {}} = filterData;
                const {date: dates = [], city_id: cities = []} = filter;
                // const {city_id = [2], date = ['2022-07-01']} = selected;
                this.citiesList = cities;
                this.filterDataAccessDates = dates;
                this.filterDataSelected = selected;
                this.setIsFilterLoading(false);
            });
        },

        /**
         * Получение расширенного фильтра
         * @async
         */
        async fetchExtendFilter() {
            this.isFilterLoading = true

            if(!this.isFilterOpen){
                this.setIsFilterLoading(true);
            }

            ServiceFilter.postExtra(this.filterDataSelected, (extendFilter) => {
                const {sideCount = 0, filterDataParams = []} = extendFilter;
                this.filterSidesNumber = sideCount;
                this.filterDataParams = filterDataParams;

                this.isFilterLoading = false;
                if(!this.isFilterOpen){
                    this.setIsFilterLoading(false);
                }
            });
        },

        onHamburger(isShow) {
            this.isMenuOpen = isShow;
            this.isFilterOpen = false;
        },

        onFilter() {
            this.isFilterOpen = !this.isFilterOpen;
            this.isMenuOpen = false;
        },

        /**
         * Сохранить выбранные значения фильтра
         * @param {String} key - тип выбранных данных (город/дата)
         * @param {Array} value - значения выбранных данных (id городов/даты)
         * @param {Boolean} isReset - флаг сброса экстра фильтра
         */
        onCheckFilter(key, value, isReset = false) {
            if(isReset){
                let {city_id: cityId = [], date = []} = this.filterDataSelected;
                let filterSelected = {city_id: cityId, date};
                filterSelected[key] = value;
                this.filterDataSelected = {...filterSelected};
            }else{
                this.filterDataSelected = { ...this.filterDataSelected, [key]: value };
            }

            this.fetchExtendFilter();
        },

        onTransfer(filterItemId) {
            const filterItem = this.filterDataParams.find(item => item.id === filterItemId)
            filterItem.hidden = !filterItem.hidden
            this.filterDataParams = this.filterDataParams.slice()
        },

        onResetFilter() {
            let {city_id: cityId = [], date = []} = this.filterDataSelected;
            this.filterDataSelected = {city_id: cityId, date};
            this.fetchExtendFilter();
        },

        /**
         * Событие выбора городов
         * @param {Array} cityIds - данные об удаляемом тэге
         */
        async onCheckCities(cityIds = []) {
            // this.onCheckFilter('city_id', result, true);
            let {city_id: cityId = [], date = []} = this.filterDataSelected;
            let filterSelected = {city_id: cityId, date};
            filterSelected["city_id"] = cityIds.map((el) => Number(el));
            await ServiceFilter.postFilterMain(filterSelected.city_id, (filterData) => {
                const {filter = {}, selected = {}} = filterData;
                const {date: dates = [], city_id: cities = []} = filter;
                this.citiesList = cities;
                this.filterDataAccessDates = dates;
                this.filterDataSelected = {city_id: filterSelected.city_id, date: selected.date || []};
                this.fetchExtendFilter();
                // this.setIsFilterLoading(false);
            });
            // this.fetchFilterData()
        },

        /**
         * Событие выбора дат
         * @param {Array} approveDates - выбранные даты
         */
        onApproveDatepicker(approveDates = []) {
            const result = approveDates.map((date) => {
                let month = String(date.month);
                month = month.length === 1 ? `0${month}` : month;
                const year = date.year;

                return `${year}-${month}-01`;
            })

            this.onCheckFilter('date', result, true);
        },

        async logout() {
            const {currentRoute = {}} = this.$router;
            const {name = ''} = currentRoute;
            if (name === 'map' || name === 'price') {
                await ServiceAuth.logout();
            }
        },

        historyDropdownChangeVisible(isShow) {
            if(isShow && this.historyItems.length === 0) {
                this.postHistory(this.afterPostHistory);
            }
        },

        onUserMenuItem(key = '') {
            ServiceUser.goToUserMenuItem(key);
        }
    }
}
</script>

<style lang="scss" scoped>
.layout {
    height: 100vh;
    display: flex;

    .loading {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
    }

    &__main {
        position: relative;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    &__content {
        position: relative;
        // z-index: 100;
        width: 100%;
        height: 100%;
        background: #fff;
        // overflow: hidden;
        padding: 10px 40px 40px 40px;
    }

    &__filter {
        padding: 20px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        // bottom: 0;
        background: #fff;
        z-index: 5;
        box-shadow: 0 10px 10px 0 RGB(0 0 0 / 20%);

        @media screen and (max-width: 830px) {
            bottom: 0;
        }

        // height: fit-content;
    }
}
</style>
